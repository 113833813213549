import useMediaQuery from "hooks/useMediaQuery";
import { IconNotification } from "utils/app_icons";
import BottomNavBarItem from "./bottom_nav_bar_item";
import p_image from "assets/p_image.png";
import logo_s from "assets/logo_s.svg";
import useLoginController from "pages/auth/login_page/login_controller";

const BottomNavBar = ({ items, onItemChanged, currentIndex }) => {
  const size = useMediaQuery();
  const isLargeScreen = size.width >= 1024;
  const user = useLoginController().user;
  return (
    <>
      {!isLargeScreen ? (
        <div className="sm:py-3">
          <div className="h-16 shadow-md border border-[#D6BBFB] flex items-center px-4 space-x-3 justify-between bg-white rounded-tr-3xl rounded-tl-3xl sm:w-[80%] mx-auto">
            {items != null &&
              items.map((item, ind) => {
                return (
                  <BottomNavBarItem
                    key={item.title}
                    icon={item.icon}
                    title={item.title}
                    isActive={currentIndex === ind}
                    onPressed={() => {
                      onItemChanged(ind);
                    }}
                  />
                );
              })}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <img src={logo_s} alt="logo" className="w-[80px] pb-12" />
          <div className="h-[35px] w-[35px] rounded-full bg-slate-200 overflow-clip">
            <img
              src={user.image ? user.image : p_image}
              alt="profile"
              className="w-full h-auto"
            />
          </div>
          <div className="flex flex-col space-y-6 pt-6">
            {items != null &&
              items.map((item, ind) => {
                return (
                  <BottomNavBarItem
                    key={item.title}
                    icon={item.icon}
                    title={item.title}
                    isActive={currentIndex === ind}
                    onPressed={() => {
                      onItemChanged(ind);
                    }}
                  />
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default BottomNavBar;
