// TODO: Implement Lazy Import

import CustomAppBar from "components/layout/app_bar";
import PageLayout from "components/layout/page_layout";
import AuthCard from "../components/auth_card";
import AuthContainer from "../components/auth_container";
import useSignUpController from "./signup_controller";
import useAppNavigator from "utils/app_navigation";
import AppRoutes from "router/route_paths";
import { useEffect } from "react";
import { Outlet, useLoaderData, useLocation } from "react-router-dom";
import { useState } from "react";

const SignUpPage = () => {
  const controller = useSignUpController();
  const navigate = useAppNavigator();
const location = useLocation()
  const indicators  = [0, 1].map(ind => {
    const isActive = ind===0|| location.pathname.includes(`${AppRoutes.signUp}/2`);
    const isLast = ind===1;
    return   (
          <div className="flex items-center" key={ind}>
            <div
              className={`h-[15px] w-[15px]  rounded-full transition-colors duration-1000 ${
                isActive ? "bg-sprimary-80" : "border border-slilac"
              }`}
            ></div>
            {!isLast && <div className="w-[40px] h-[1px] bg-sgrey-3"></div>}
          </div>
        );
  })
  
  return (
    <PageLayout animationType='none'>

    <AuthContainer modalHeight="h-[70%]">
        <CustomAppBar />
        <div className="absolute h-full flex flex-col items-center justify-center w-full">
          <AuthCard>
            <div className="flex flex-col py-[2rem] items-start h-full">
              <div className="flex px-4">{indicators}</div>
              {/* {controller.pages[controller.currentPage]} */}
              <Outlet />
              <div
                className={` flex justify-center w-full space-x-2 text-[12px] text-sgrey-2 `}
              >
                <p>Already have an account?</p>
                <button
                  className="text-sprimary font-bold"
                  onClick={() => navigate.to(AppRoutes.logIn)}
                >
                  Login
                </button>
              </div>
            </div>
          </AuthCard>
        </div>
    </AuthContainer>
    </PageLayout>

  );
};

export default SignUpPage;
