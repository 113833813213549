import { AnimatePresence, motion } from "framer-motion";
import React from "react";

/**
 *
 * @param {object} props
 * @param {object} props.data
 * @param {Array<string>} props.data.labels
 * @param {{data: Array<number>, barColors: Array<string>}} props.data.datasets
 * @returns
 */
const BarChart = ({ data }) => {
  return (
    <div className="w-full h-full">
      <div className="flex space-x-[6px] h-full">
        {data.labels.map((label, ind) => (
          <Bar
          key={ind}
            label={label}
            data={data.datasets.data[ind]}
            biggest={Math.max(...data.datasets.data)}
            color={data.datasets.barColors[ind]}
          />
        ))}
      </div>
    </div>
  );
};

export default BarChart;

const Bar = ({ label, data, biggest, color }) => {
  const barHeight = (data / biggest) * 100;
  const duration = (data / biggest) * 1;
  return (
    <AnimatePresence>
      <motion.div className="w-full h-full flex flex-col items-center space-y-1 justify-end overflow-y-clip">
        <motion.div
          initial={{
            height: 0,
          }}
          animate={{
            height: `${barHeight}%`,
            backgroundColor: color,
            transition: {
              duration: duration,
            },
          }}
          className="bg-[#E9D7FE] rounded-[3px] w-full"
          //   style={{ height: `${barHeight}%` }}
        ></motion.div>
        <p className="text-[7px] font-semibold text-[#ECE8FD]">{label}</p>
      </motion.div>
    </AnimatePresence>
  );
};
