// @ts-check

import BottomNavBarModel from "components/layout/bottom_nav_bar/bottom_nav_bar_model";
import { Reducers } from "global_store/reducers";
import setGlobalState, { useActions } from "global_store/useGlobalStore";
import useMediaQuery from "hooks/useMediaQuery";
import React, { useState } from "react";
import AppRoutes from "router/route_paths";
import { IconHome, IconNotification, IconProfile, IconRecent, IconWallet } from "utils/app_icons";
import History from "./history/history";
import Home from "./home/home";
import Payment from "./payment/payment";
import Profile from "./profile/profile";

/**
 * @typedef {object} State
 * @property {number} currentIndex
 */
/**
 * @type {State}
 */
const initialState = { currentIndex: 0 };
const { controllerAction, controllerReducer } = setGlobalState({
  name: "main",
  initialState,
});
export const mainReducer = controllerReducer;

const useMainActitvity = () => {
  const { state, setState } = useActions({
    reducer: Reducers.main,
    controllerAction,
  });
  const isLargeScreen = useMediaQuery().width >= 1024
  /**
   * @type {State}
   */
  const mainState = state;
  const items = isLargeScreen? [
    new BottomNavBarModel({
      icon: <IconHome />,
      title: "Home",
      // routePath: AppRoutes.home,
    }),
    new BottomNavBarModel({
      icon: <IconWallet />,
      title: "Payments",
      // routePath: AppRoutes.payment,
    }),
    new BottomNavBarModel({ icon: <IconRecent />, title: "History" }),
    new BottomNavBarModel({ icon: <IconProfile />, title: "Profile" }),
    new BottomNavBarModel({ icon: <IconNotification />, title: "Referals" }),
    new BottomNavBarModel({ icon: <IconProfile />, title: "Setting" }),
  ]:[
    new BottomNavBarModel({
      icon: <IconHome />,
      title: "Home",
      // routePath: AppRoutes.home,
    }),
    new BottomNavBarModel({
      icon: <IconWallet />,
      title: "Payments",
      // routePath: AppRoutes.payment,
    }),
    new BottomNavBarModel({ icon: <IconRecent />, title: "History" }),
    new BottomNavBarModel({ icon: <IconProfile />, title: "Profile" }),
  ];

const setcurrentIndex = (ind) => {
  setState({state: 'currentIndex', value: ind})
} 
  const pages = [<Home />, <Payment />, <History />, <Profile />];
  return { items, currentIndex: mainState.currentIndex, pages, setcurrentIndex };
};

export default useMainActitvity;
