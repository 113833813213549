import CustomButton from 'components/button'
import React from 'react'
import { IconArrowForward } from 'utils/app_icons'

const Contacts = () => {
  return (
    <div className="rounded-lg px-3 py-5 bg-slilac-40 flex justify-between items-center space-x-3">
        <div className="">
          <h3 className="stext-base font-myriadProSemibold text-sblack-600">
            Network on Skitpay
          </h3>
          <p className="text-sSm text-sgrey-2">
            Connect with contacts on Skitpay
          </p>
        </div>
        <CustomButton
          title={
            <div className="flex items-center">
              Connect <div className="pl-3"><IconArrowForward /></div>
            </div>
          }
          className="w-fit px-4"
        />
      </div>
  )
}

export default Contacts