import logo from "assets/logo.png";
import CustomButton from "components/button";
import PageLayout from "components/layout/page_layout";
import AppRoutes from "router/route_paths";
import useAppNavigator from "utils/app_navigation";
import AuthCard from "./components/auth_card";
import AuthContainer from "./components/auth_container";

const WelcomePage = () => {
  const appNavigator = useAppNavigator();
 
  return (
    <PageLayout animationType="slideIn">

    <AuthContainer>
      {/* <div className="center_item_fixed flex flex-col items-center w-full"> */}
      <div className="absolute h-full flex flex-col items-center justify-center w-full">
        <img src={logo} alt="logo" className="pb-[50px] w-[190px]" />
        <AuthCard>
          <div className="flex flex-col py-[4rem] items-center px-5">
            <h2 className={`stext-heading py-3 text-bg`}>Welcome</h2>
            <p className={`stext-base pb-8`}>Get started with Skitpay</p>
            <CustomButton
              filled={false}
              className="mb-5"
              title="Signup"
              onClick={() => {
                appNavigator.to(AppRoutes.signUp);
              }}
            />
            <CustomButton
              filled={false}
              title="Login"
              onClick={() => {
                appNavigator.to(AppRoutes.logIn);
              }}
            />
          </div>
        </AuthCard>
      </div>
    </AuthContainer>
    </PageLayout>

  );
};

export default WelcomePage;




