// @ts-check

import axios from "axios";
import { TransctionType } from "components/transaction_item";
import { dummyUserPin } from "data/dummyData";
import { TransactionType } from "enums";
import { TransactionState, TransactionStatus } from "enums";
import TransactionModel from "models/transaction_model";
import useLoginController from "pages/auth/login_page/login_controller";
import useHistoryController from "pages/main_activity/history/history_controller";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { generateTransRef } from "utils/functions";

const useAuthorizeTransaction = () => {
  const [otpValue, setotpValue] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [successModal, setsuccessModal] = useState({
    isSuccess: false,
    isModalShown: false,
  });

  /**
   * @typedef {{isModalShown: boolean, transactionDetails?: TransactionModel }} RecieptModal
   */

  /**
   * @type {[RecieptModal, import("react").Dispatch<import("react").SetStateAction<RecieptModal>>]}
   */
  const [recieptModal, setrecieptModal] = useState({
    isModalShown: false,
    transactionDetails: null,
  });

  const otpLenght = 4;

  /**
   * @type {{state: { type: string, data: {recipient: string ,  bank: string,    accountNumber: string, amount: string, equivalentAmount: string, rate: string, description: string, fee: "0", userName: string  }},}}
   */
  const { state } = useLocation();
  const hController = useHistoryController();
  const lController = useLoginController();

  const confirmOtp = async () => {
    // if (otpValue.length < otpLenght) {
    //   return;
    // }
    if (otpValue.length < otpLenght) {
      toast.warn("Pin field not complete");
      return;
    }
    setisLoading(true);

    await new Promise((resolve) => setTimeout(resolve, 4000));
    const userPin = dummyUserPin;
    console.log("OTP VAL", otpValue);
    console.log("OTP VAL", userPin);
    console.log(state, "The sate");
    if (otpValue !== userPin.toString()) {
      setsuccessModal({
        ...successModal,
        isModalShown: true,
        isSuccess: false,
      });
      setisLoading(false);
      return;
    }

    setisLoading(false);
    setsuccessModal({ ...successModal, isModalShown: true, isSuccess: true });
    const date = new Date(Date.now());
    const data = state.data;

    const reference = generateTransRef();
    const transaction = new TransactionModel({
      amount: `${lController.user.currency}${data.amount}`,
      bank: data.bank,
      accountNumber: data.accountNumber,
      description: data.description,
      equivalentAmount: data.equivalentAmount,
      fee: data.fee,
      id: Date.now().toString(),
      rate: data.rate,
      recipient:
        state.type === TransactionType.skitUser
          ? `@${data.userName}`
          : data.recipient,
      reference,
      status: TransactionStatus.success,
      type: state.type,
      date,
      state: TransactionState.debit,
    });
    await hController.addTransaction(transaction);
    lController.deductBalance(parseInt(data.amount));

    setTimeout(() => {
      //   setsuccessModal({ ...successModal, isModalShown: false });
      setrecieptModal({
        ...recieptModal,
        isModalShown: true,
        transactionDetails: transaction,
        // transactionDetails: {
        //   amount: `₦${state.data.amount}`,
        //   date: "Oct 18, 2022 / 01:00PM (WAT)",
        //   equivalentAmount: "INR457.69",
        //   fee: " ₦0.00",
        //   from: "@JAMESAKINYOLA",
        //   to: "@SAI49",
        //   ref: "ZM-1793t993663",
        //   status: "Successful",
        //   type: "DEBIT",
        // },
      });
    }, 2000);
  };

  return {
    setotpValue,
    successModal,
    recieptModal,
    confirmOtp,
    otpLenght,
    setsuccessModal,
    setrecieptModal,
    isLoading,
  };
};

export default useAuthorizeTransaction;
