import { useState } from "react";
import AppRoutes from "router/route_paths";
import useAppNavigator from "utils/app_navigation";

const useOtpController = () => {
  const [otpValue, setotpValue] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [modal, setmodal] = useState({
    isShown: false,
    message: null,
    subMessage: "",
  });
  const otpLength = 4;
  const navigator = useAppNavigator();

  console.log(otpValue);
  const verifyOtp = (val) => {
    const isOtpComplete = val.length >= otpLength;
    if (!isOtpComplete) {
      return;
    }

    if (document.activeElement) {
      document.activeElement.blur();
    }
    setisLoading(true);
    setTimeout(() => {
      setisLoading(false);
      if (val === "1234") {
        navigator.to(AppRoutes.mainActivity);
      } else {
        setmodal({
          isShown: true,
          subMessage: "Try using 1234 as otp pin to see home screen",
        });
      }
    }, 2000);
  };
  return { setotpValue, verifyOtp, otpLength, isLoading, modal, setmodal };
};

export default useOtpController;
