import NavBar from "components/layout/nav_bar";
import PageLayout from "components/layout/page_layout";
import Scaffold from "components/layout/scaffold";
import React from "react";
import useAppNavigator from "utils/app_navigation";
import profileCardDecor from "assets/decor/profile_card_decor.svg";
import p_image from "assets/p_image.png";
import {
  IconAddContainer,
  IconCopy,
  IconEdit,
  IconShare,
} from "utils/app_icons";
import Profile from "pages/main_activity/profile/profile";
import CustomInput from "components/form/custom_input";
import CustomForm from "components/form/custom_form";
import useFormState from "components/form/form_state";
import CustomButton from "components/button";
import useAppNotifications from "components/global/notifications/use_app_notifications";
import useLoginController from "pages/auth/login_page/login_controller";

const EditProfile = () => {
  const navigate = useAppNavigator();
  const formStateProps = useFormState();
  return (
    <Scaffold appBar={<NavBar title="Edit Profile" canGoBack={true} />}>
      <PageLayout animationType="fadeIn">
        <ProfileCard />
        <div className="py-3 space-y-4 px-4">
          <CustomForm formStateProps={formStateProps} className="space-y-5">
            <CustomInput
              name="first_name"
              title="First name"
              placeholder="James"
            />
            <CustomInput
              name="last_name"
              title="Last name"
              placeholder="Akinyola"
            />
            <CustomInput
              name="phone"
              title="Phone number"
              placeholder="+2348110842186"
            />
            <CustomInput
              name="email"
              title="Email address"
              placeholder="jamesakinyola@gmail.com"
            />
          </CustomForm>
          <div className="py-5">
            <CustomButton title="Save Changes" />
          </div>
        </div>
      </PageLayout>
    </Scaffold>
  );
};

export default EditProfile;

const ProfileCard = () => {
  const hasUserName = false;
  const notification = useAppNotifications();
  const user = useLoginController().user;

  return (
    <div className="w-full bg-sprimary-80 relative overflow-clip">
      <img
        src={profileCardDecor}
        alt="p-decor"
        className="absolute h-[115%] right-0  w-[80%] "
      />
      <div className="py-5 grid place-items-center relative z-[1] text-white space-y-3">
        <div className="w-[80px] h-[80px] overflow-clip rounded-full">
          <img
            src={user.image ? user.image : p_image}
            alt="p_img"
            className="h-full w-full"
          />
        </div>
        <p className="font-myriadProBold text-[20px]">James Akinyola</p>
        <div className="flex space-x-4">
          <button
            className="px-3 h-[30px] py-1 rounded-md bg-slilac text-sm font-myriadProSemibold flex space-x-1 items-center"
            onClick={() =>
              notification.showBottomModal("profile-username", {
                component: (
                  <div className="bg-white w-full flex justify-center items-center flex-col px-5 py-6 shadow-xl rounded-t-[25px] border text-center space-y-3">
                    <h2 className="text-sblack-600 text-2xl">
                      Create a Username
                    </h2>
                    <p className="text-sblack-600 text-xs">
                      Send and receive money easily with a username. Make sure
                      to crosscheck your input as this cannot be changed
                    </p>
                    <div className="w-full">
                      <CustomInput
                        name="username"
                        title="Username"
                        className="w-full"
                      />
                    </div>
                  </div>
                ),
              })
            }
          >
            <IconEdit />
            <p>{hasUserName ? "@belema" : "create username"}</p>
          </button>
          <div className="w-[30px] h-[30px] rounded-md bg-slilac grid place-items-center">
            <IconCopy />{" "}
          </div>
          <div className="w-[30px] h-[30px] rounded-md bg-slilac grid place-items-center">
            <IconShare />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
