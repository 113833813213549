// @ts-check
import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

/**
 *
 * @param {object} param
 * @param {string} param.reducer 
 * @param {{setState: (actions: any)=>any}} param.controllerAction
 */
const useActions = ({ reducer, controllerAction }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state[reducer]);

  /**
   *
   * @param {{state: string, value}} param
   */
  const setState = ({ state, value }) => {
    dispatch(controllerAction.setState({ state, value }));
  };
  return { state, setState };
};

/**
 * - This function is used to create a slice and returns a reducer that would be paseed into the store
 * - After getting the reducer from this function, export and assign a constant with custom name as in const mainReducer =  controllerReducer
 * - Then import in the store file and add under the list of reducers
 * @param {object} props
 * @param {string} props.name - The unique name that would be used to identify the slice
 * @param {object} props.initialState - The initial state of the current component
 */
const setGlobalState = ({ name, initialState }) => {
  const setState = (state, action) => {
    const stateKey = action.payload.state;
    const newStatevalue = action.payload.value;
    state[stateKey] = newStatevalue;
  };

  const globalSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: { setState },
  });

  const controllerReducer = globalSlice.reducer;
  const controllerAction = globalSlice.actions;
  return { controllerAction, controllerReducer };
};

export { useActions };
export default setGlobalState;
