import ConverterItem from "components/currency_converter/converter_item";
import React from "react";


const CurrencyExchange = () => {
  return (
    <section className="pt-5">
      <p className="text-sm font-semibold text-sgrey-600 pb-3">Currency Exchange</p>
      <div className="border-2 rounded-2xl px-4 py-5 space-y-4">
        <ConverterItem text='You send' />
        <ConverterItem text='You Recieve' />
       {/* <CurrencyItem />
       <CurrencyItem /> */}
       <div className="flex justify-between text-xs text-sprimary-80 font-medium">
        <p>Charge = 0.99%</p>
        <p>Rate = 472</p>
       </div>
      </div>
    </section>
  );
};

export default CurrencyExchange;
