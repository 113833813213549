import useMediaQuery from "hooks/useMediaQuery";
import React from "react";

const BottomNavBarItem = ({ onPressed, isActive, icon, title }) => {
  const size = useMediaQuery();
  const isLargeScreen = size.width >= 1024;
  return (
    <>
      {isLargeScreen ? (
        <div
          onClick={onPressed}
          className={`flex  px-3 py-2 items-center h-fit rounded-xl transition-[background-color] duration-[400ms] space-x-3  w-fit ${
            isActive
              ? "bg-[#670DBE] text-white fill-white"
              : "text-slilac bg-transparent fill-slilac"
          }`}
        >
          <div className="text-[1.2rem]">{icon}</div>
          <p
            className={`text-sSm font-myriadProSemibold overflow-hidden  cursor-pointer `}
          >
            {title}
          </p>
        </div>
      ) : (
        <div
          onClick={onPressed}
          className={`flex  px-3 py-2 items-center h-fit w-fit rounded-xl transition-[background-color] duration-[400ms] ${
            isActive
              ? "bg-[#670DBE] text-white space-x-3 fill-white"
              : "text-slilac space-x-0 bg-transparent fill-slilac"
          }`}
        >
          <div className="text-[1.2rem]">{icon}</div>
          <p
            className={`text-sSm font-myriadProSemibold overflow-hidden transition-all duration-700 delay-200 cursor-pointer ${
              !isActive && "w-0"
            }`}
          >
            {title}
          </p>
        </div>
      )}
    </>
  );
};

export default BottomNavBarItem;
