import { AnimatePresence } from "framer-motion";
import React from "react";
import BottomModal from "./all_notifications/bottom_modal";
import Dialogue from "./all_notifications/dialogue";
import NotificationProvider from "./app_notification_context";
import useAppNotifications from "./use_app_notifications";

const AppNotifications = ({ children }) => {
  return (
    <NotificationProvider>
      <>
      <MainNotifs />
      {children}
      </>
    </NotificationProvider>
  );
};

export default AppNotifications;

const MainNotifs = () => {
  const { notifications, closeModal } = useAppNotifications();
  
  return (
    <AnimatePresence>
      {notifications.map((notif) => {
        switch (notif.type) {
          case "bottom_modal":
            return (
              <BottomModal
                key={notif.id}
                components={notif.component}
                closeModal={() => closeModal()}
              />
            );
          case "dialogue":
            return (
              <Dialogue
                key={notif.id}
                components={notif.component}
                closeModal={() => closeModal()}
              />
            );

          default:
            return <></>;
        }
      })}
    </AnimatePresence>
  );
};
