import { useState } from "react";

/**
 * @typedef formprops
 * @property {string} values
 * @property {string} errors
 * @property {(event: any) => void} handleChange
 * @property {() => boolean} validateForm
 * @property {() => boolean} isValid
 * @property {() => boolean} validate
 * @property {boolean} isFormValid
 * @property {(validatorObject: any) => void} addValidators
 * @property {(children: any, validatrs: any) => void} addInitialErrors
 */

/**
 *
 * @returns {formprops}
 */
const useFormState = () => {
  const [values, setvalues] = useState({});
  const [errors, seterrors] = useState({});
  const [storedDummyErrors, setstoredDummyErrors] = useState({});
  const [isFormValid, setisFormValid] = useState(false);
  const [hasValidated, sethasValidated] = useState(false);
  const [validators, setvalidators] = useState({});
  let storedErrors = {};

  const addValidators = (validatorObject) => {
    if (Object.keys(validatorObject).length < 1) {
      setisFormValid(true);
    }
    setvalidators(validatorObject);
  };

  const validate = (name, value) => {
    const errorMessage =
      validators[name] != null ? validators[name](value) : null;
    storedErrors = { ...storedDummyErrors, [name]: errorMessage };
    setstoredDummyErrors(storedErrors);
    if (!hasValidated) {
      return;
    }
    seterrors(storedErrors);
  };

  const validateForm = () => {
    sethasValidated(true);
    if (isFormValid) {
      seterrors({});
    } else {
      seterrors(storedDummyErrors);
    }

    return isFormValid;
  };

  const addInitialErrors = (children, validatrs) => {
    const initialErrors = {};
    for (const name of children) {
      initialErrors[name] =
        validatrs[name] != null
          ? validatrs[name]("") !== undefined
            ? validatrs[name]("")
            : null
          : null;
    }
    setstoredDummyErrors(initialErrors);
  };

  const isValid = (newErrors) => {
    const errorArray = Object.values(newErrors);
    setisFormValid(
      errorArray.every((val) => val === errorArray[0] && val === null)
    );
    return errorArray.every((val) => val === errorArray[0]);
  };

  const handleChange = (event) => {
    event.persist();

    let name = event.target.name;
    let value = event.target.value;
    validate(name, value);

    isValid({
      ...storedDummyErrors,
      [name]: validators[name] != null ? validators[name](value) : null,
    });
    setvalues({
      ...values,
      [name]: value,
    });
  };

  const formStateProps = {
    values,
    errors,
    isFormValid,
    handleChange,
    addInitialErrors,
    validateForm,
    addValidators,
  };
  return formStateProps;
};

export default useFormState;
