import NavBar from "components/layout/nav_bar";
import Scaffold from "components/layout/scaffold";
import PaymentItem from "pages/main_activity/payment/sections/payment_item";
import React from "react";

const Account = () => {
  return (
    <Scaffold appBar={<NavBar title="Account" canGoBack={true} />}>
      <div className="px-4 py-3">
        <PaymentItem />
      </div>
    </Scaffold>
  );
};

export default Account;
