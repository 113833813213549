import AppNotifications from "components/global/notifications/app_notifications";
import { useEffect } from "react";
import AppRouter from "router";

const App = () => {
  useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", "transparent");
  }, []);

  return (
    <AppNotifications>
      <AppRouter />
    </AppNotifications>
  );
};

export default App;
