// @ts-check
import { TransactionStatus, TransactionType } from "enums";
import { AnimatePresence, motion } from "framer-motion";
import TransactionModel from "models/transaction_model";
import useLoginController from "pages/auth/login_page/login_controller";
import React from "react";
import { IconClose, IconSend } from "utils/app_icons";
import { formatDate, formatTime } from "utils/functions";

// /**
//  * @typedef {object} TransactionData
//  * @property {string} amount
//  * @property {string} from
//  * @property {string} to
//  * @property {string} type
//  * @property {string} date
//  * @property {string} ref
//  * @property {string} equivalentAmount
//  * @property {'Successful' | 'Failed'} status
//  * @property {string} fee
//  */

// * @param {{amount: number, from: string, to: string ,type: string ,date: Date,ref:string, equivalentAmount: number , status: 'Successful' | 'Failed', fee:number}} props.transactionData

/**
 *
 * @param {object} props
 * @param {boolean=} props.isShown
 * @param {TransactionModel} props.transactionData
 * @param {()=>void=} props.closeModal
 * @param {boolean} props.isBarrearDismisable
 */
const TransactionRecieptModal = ({
  isShown = false,
  isBarrearDismisable = false,
  transactionData,
  closeModal,
}) => {
  const lcontroller = useLoginController();
  // console.log(transactionData.date, "number");
  return (
    <AnimatePresence>
      {isShown && (
        <div
          className="fixed h-full w-full flex justify-center items-center px-5 z-40 top-0 left-0 bg-[rgba(0,0,0,0.32)]"
          onClick={isBarrearDismisable ? closeModal : null}
        >
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ opacity: 0 }}
            className="rounded-2xl px-5 py-6 bg-white mt-[-60px] shadow-lg grid place-items-center border relative w-full max-w-[500px]"
          >
            <button
              className="grid place-items-center rounded-full p-4 absolute top-[15px] left-[15px] active:bg-slate-100"
              onClick={closeModal}
            >
              <IconClose />
            </button>
            <div className="w-[58px] h-[58px] rounded-full bg-[#EBF5EB] grid place-items-center">
              <IconSend className="fill-sprimary-80" />
            </div>
            <h2 className="text-[28px] pt-2">{transactionData.amount}</h2>
            {transactionData.type === TransactionType.bank ? (
              <p className="text-xs text-sgrey-2 text-center font-myriadProSemibold px-5">
                TRF FROM{" "}
                {`${lcontroller.user.firstName.toUpperCase()} ${lcontroller.user.lastName.toLocaleUpperCase()}`}
                {" \n"}
                TO {transactionData.recipient.toUpperCase()} <br></br> BANK
                NAME: {transactionData.bank.toUpperCase()} / TYPE:{" "}
                {transactionData.state.toUpperCase()}
              </p>
            ) : (
              <p className="text-xs text-sgrey-2 text-center font-myriadProSemibold px-5">
                TRF FROM{" "}
                {`${lcontroller.user.firstName.toUpperCase()} ${
                  lcontroller.user.lastName
                }`.toLocaleUpperCase()}{" "}
                TO {transactionData.recipient.toUpperCase()} SKITPAY ACCOUNT /
                TYPE: {transactionData.state.toUpperCase()}
              </p>
            )}
            <div className="py-5 w-full">
              <TransItem
                title="Transaction Date"
                value={`${formatDate(
                  new Date(transactionData.date),
                  false
                )} / ${formatTime(new Date(transactionData.date))} `}
                isFirst
              />
              {transactionData.type === TransactionType.bank && (
                <TransItem
                  title="Account Number"
                  value={transactionData.accountNumber}
                />
              )}
              <TransItem
                title="Reference"
                value={`SKIT-${transactionData.reference}`}
              />
              <TransItem
                title="Equivalent amount"
                value={transactionData.equivalentAmount}
              />
              <TransItem
                title="Payment Status"
                value={
                  transactionData.status === TransactionStatus.success
                    ? "Successful"
                    : ""
                }
              />
              <TransItem title="Transaction Fee" value={transactionData.fee} />
            </div>
            <div className="grid grid-cols-2 gap-x-4 py-4">
              <RecieptButton title="Share Receipt" />
              <RecieptButton title="Cancel" isPrimary={false} />
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default TransactionRecieptModal;

const TransItem = ({ title, value, isFirst = false }) => {
  return (
    <div
      className={`w-full text-xs flex justify-between space-x-3 py-3 border-b px-3 border-[#EFEFEF] text-sgrey-2 ${
        isFirst && "border-t"
      }`}
    >
      <p className="whitespace-nowrap">{title}</p>{" "}
      <p className="font-myriadProSemibold">{value}</p>
    </div>
  );
};

const RecieptButton = ({ title, isPrimary = true }) => {
  return (
    <button
      className={`px-5 rounded-xl  h-14 grid place-items-center stext-sm  font-myriadProSemibold shadow-[0px_1px_2px_rgba(16,24,40,0.05)] ${
        isPrimary ? "bg-slilac text-white" : "bg-[#FCECEC] text-[#CB1F1F]"
      }`}
    >
      {title}
    </button>
  );
};
