import useFormState from "components/form/form_state";
import {
  curencyShortcodetoSymbol,
  dummyBanks,
  dummyConversions,
  dummysupportedCountries,
} from "data/dummyData";
import { TransactionType } from "enums";
import { m } from "framer-motion";
import useLoginController from "pages/auth/login_page/login_controller";
import { useState } from "react";
import AppRoutes from "router/route_paths";
import useAppNavigator from "utils/app_navigation";
import FormVaidation from "utils/form_validation";
const { default: setGlobalState } = require("global_store/useGlobalStore");

// const initialState = {};

// const { controllerAction, controllerReducer } = setGlobalState({
//   name: "send-to-bank",
// });

const useSendToBank = () => {
  // Laoding
  const [isGettingCountries, setisGettingCountries] = useState(false);
  const [isGettingBanks, setisGettingBanks] = useState(false);
  const [isGettingRecipient, setisGettingRecipient] = useState(false);
  const [isGettingEquivalentAmount, setisGettingEquivalentAmount] =
    useState(false);

  // Values
  const [countries, setcountries] = useState([]);
  const [banks, setbanks] = useState({});

  /**
   * @type {[equivalentAmount: {name: string, isOk: Boolean }, setequivalentAmount: ({name: string, isOk: Boolean })=>{}]}
   */
  const [recipient, setrecipient] = useState({ isOk: false });

  /**
   * @type {[equivalentAmount: {value: string, text: string, isOk: Boolean, rate: string }, setequivalentAmount: ({value: number, text: string, isOk: Boolean, rate: string },)=>{}]}
   */
  const [equivalentAmount, setequivalentAmount] = useState({ isOk: false });

  const formprops = useFormState();
  const navigator = useAppNavigator();
  const lController = useLoginController();
  const userShortCode = lController.user.country.shortCode;

  const isDisabled = () => {
    // console.log(
    //   !(formprops.isFormValid && recipient.isOk && equivalentAmount.isOk),
    //   "is Disabled"
    // );
    return !(formprops.isFormValid && recipient.isOk && equivalentAmount.isOk);
  };

  const getSupportedCountries = async () => {
    setisGettingCountries(true);
    await new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });
    setisGettingCountries(false);
    setcountries(dummysupportedCountries);
  };

  const getBanksFromCountry = async (country) => {
    setisGettingBanks(true);
    await new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });
    setisGettingBanks(false);
    setbanks(dummyBanks[country] ?? {});
    // return dummyBanks[country];
  };

  const continuePayment = async () => {
    const fee = `${curencyShortcodetoSymbol[userShortCode]}${(
      (0.99 / 100) *
      +formprops.values["amount"]
    ).toFixed(1)}`;
    navigator.to(AppRoutes.confirmTransaction, {
      state: {
        type: TransactionType.bank,
        data: {
          recipient: recipient.name,
          bank: formprops.values["bank"],
          accountNumber: formprops.values["account-num"],
          amount: formprops.values["amount"],
          equivalentAmount: equivalentAmount.value,
          rate: equivalentAmount.rate,
          description: formprops.values["note"],
          fee: fee,
        },
      },
    });
  };
  /**
   * @param {number} accountNumber
   */
  const getRecipientFromAccountNumber = async (accountNumber) => {
    if (accountNumber === null) {
      return;
    }
    setisGettingRecipient(true);
    await new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });
    console.log(formprops.values["country"], "Countrey");
    if (formprops.values["country"]) {
      if (formprops.values["bank"]) {
        // console.log("in for loop");
        dummyBanks[formprops.values["country"]][
          formprops.values["bank"]
        ].userAccounts.every((user) => {
          console.log(user);
          console.log(user.accountNumber, "USAcc");
          console.log(accountNumber, "AccNum");
          console.log(
            user.accountNumber === +accountNumber,
            "Is accoiumt same"
          );
          if (user.accountNumber === accountNumber) {
            setrecipient({ ...user, isOk: true });
            return false;
          } else {
            setrecipient({ name: "User not found", isOk: false });
            return true;
          }
        });
      }
    }
    setisGettingRecipient(false);
  };

  /**
   *
   * @param {number} amount
   * @param {string} countryShort
   */
  const getEquivalentamount = async (amount) => {

    if (amount === null || amount === undefined) {
      return;
    }
    if (FormVaidation.validateAmount(amount) !== null) {
      console.log("The Amount is Nt valid");
      return;
    }
    setisGettingEquivalentAmount(true);
    const toCountry = dummysupportedCountries.find(
      (country) => country.name === formprops.values["country"]
    );
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setisGettingEquivalentAmount(false);

    const conversionMap = dummyConversions.find((conversion) => {
      return (
        conversion.countries.includes(toCountry.shortCode) &&
        conversion.countries.includes(userShortCode)
      );
    });

    const theRate =
      userShortCode === toCountry.shortCode
        ? 1
        : conversionMap.countries[0] === userShortCode
        ? conversionMap.value
        : 1 / conversionMap.value;
    // const theRate = Object.values(dummyConversions).find(
    //   (rate) => rate.from === "NGN" && rate.to === toCountry.shortCode
    // );
    const rate = theRate * amount;
    // console.log(rate.toFixed(2), "Amount");
    setequivalentAmount({
      text: `The equivalent amount is = ${
        curencyShortcodetoSymbol[toCountry.shortCode]
      }${rate.toFixed(2)}`,
      value: `${curencyShortcodetoSymbol[toCountry.shortCode]}${rate.toFixed(2)}`,
      rate: `1${userShortCode} / ${rate.toFixed(2)}${toCountry.shortCode}`,
      isOk: true,
    });
  };
  return {
    getSupportedCountries,
    getBanksFromCountry,
    getRecipientFromAccountNumber,
    getEquivalentamount,
    isDisabled,
    isGettingEquivalentAmount,
    isGettingCountries,
    isGettingBanks,
    equivalentAmount,
    countries,
    banks,
    formprops,
    isGettingRecipient,
    recipient,
    continuePayment,
  };
};

export default useSendToBank;
