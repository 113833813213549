import React, { createContext, useContext, useState } from "react";

export const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const [notifications, setnotifications] = useState([
    // {
    //   type: "bottom_modal",
    //   id: "errr",
    //   component: <p className="bg-green-300 w-full pl-20">Hello</p>
    // },
    // {
    //   type: "bottom_modal",
    //   id: "errrs",
    //   component: <p className="bg-red-300">Hellossssss</p>
    // },
    // {
    //   type: "dialogue",
    //   id: "one",
    // component: <p className="bg-white w-[80%]">Hello There</p>
    // },
  ]);
  return (
    <NotificationContext.Provider value={{ notifications, setnotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
