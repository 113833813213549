import { Reducers } from "global_store/reducers";
import setGlobalState, { useActions } from "global_store/useGlobalStore";
import TransactionModel from "models/transaction_model";
import React from "react";

// * @property {Array<{id:string, recipient: string, amount: string, status: number, referenceNumber: string, equivalentAmount: string, fee: string, date: Date, type: string, accountNumber: string, option: string}>} transactions

/**
 * @typedef {Object} State
 * @property {Array} transactions
 */

/**
 * @type {State}
 */
const initialState = {
  transactions: [],
};

const name = "transaction-history";
const { controllerAction, controllerReducer } = setGlobalState({
  initialState,
  name,
});

export const historyReducer = controllerReducer;

const useHistoryController = () => {
  const { state, setState } = useActions({
    controllerAction,
    reducer: Reducers.transactionHistory,
  });
  /**
   * @type {State}
   */
  const mainState = state;

  /**
   *
   * @param {TransactionModel} transaction
   */
  const addTransaction = async (transaction) => {
    const newTrans = [...mainState.transactions];
    await new Promise((resolve) => setTimeout(resolve, 1000));
    newTrans.push(transaction.toJson());
    setState({ state: "transactions", value: newTrans });
  };

  const clearAllTransactions = () => {
    console.log("clear trans");
    console.log(mainState.transactions);
    setState({ state: "transactions", value: [] });
  };
  return { addTransaction, clearAllTransactions, state: mainState };
};

export default useHistoryController;
