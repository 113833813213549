import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const BottomModal = ({ components, closeModal, id }) => {
  return (
    <div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{opacity: 0}}
        onClick={closeModal}
        className="bg-black bg-opacity-30 fixed w-full h-full top-0 left-0 z-40"
      ></motion.div>
      <motion.div
        initial={{ translateY: '100%' }}
        animate={{translateY: '0%' }}
        exit={{ translateY: '100%' }}
        
        transition={{
          bounce: 0,
        }}
        className="fixed bottom-0 left-0 w-full rounded-3xl z-50"
      >
        {components}
      </motion.div>
    </div>
  );
};

export default BottomModal;
