import CustomInput from "components/form/custom_input";
import BottomNavBar from "components/layout/bottom_nav_bar/bottom_nav_bar";
import NavBar from "components/layout/nav_bar";
import PageLayout from "components/layout/page_layout";
import Scaffold from "components/layout/scaffold";
import useMediaQuery from "hooks/useMediaQuery";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import AppRoutes from "router/route_paths";
import useAppNavigator from "utils/app_navigation";
import useMainActitvity from "./main_activity_controller";

const MainActivity = () => {
  const controller = useMainActitvity();
  const location = useLocation();
  const isIndex = location.pathname === "/";
  const isLargeScreen = useMediaQuery().width >= 1024;
  const navigator = useAppNavigator();

  return (
    <div className="h-screen overflow-y-clip">
      <Scaffold
        showAd={true}
        bottomNavBar={
          <BottomNavBar
            items={controller.items}
            onItemChanged={(val) => {
              controller.setcurrentIndex(val);
              if (!isIndex) {
                navigator.to(AppRoutes.mainActivity);
              }
            }}
            currentIndex={controller.currentIndex}
          />
        }
      >
        {isLargeScreen ? (
          isIndex ? (
            controller.pages[controller.currentIndex]
          ) : (
            <Outlet />
          )
        ) : (
          controller.pages[controller.currentIndex]
        )}
      </Scaffold>
     </div>
  );
};

export default MainActivity;
