import CustomButton from "components/button";
import NavBar from "components/layout/nav_bar";
import PageLayout from "components/layout/page_layout";
import Scaffold from "components/layout/scaffold";
import { TransactionType } from "enums";
import useLoginController from "pages/auth/login_page/login_controller";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AppRoutes from "router/route_paths";
import { IconTick } from "utils/app_icons";
import useAppNavigator from "utils/app_navigation";

const ConfirmTransaction = () => {
  const navigate = useAppNavigator();

  /**
   * @type {{state: { type: string, data: {recipient: string   bank: string    accountNumber: string   amount: string   equivalentAmount: string    rate: string    description: string    fee: "0", userName: string  }},}}
   */
  const { state } = useLocation();
  const [hasAccepted, sethasAccepted] = useState(false);

  const confirmPayment = () => {
    if (!hasAccepted) {
      toast.warning("Click on agree checkbox to continue");
      return;
    }
    navigate.to(AppRoutes.authorizetransaction, { state: state });
  };

  const isSKitUser = state.type === TransactionType.skitUser;

  // console.log(isSKitUser);
  const user = useLoginController().user;
  return (
    <PageLayout>
      <ToastContainer />
      <Scaffold
        appBar={<NavBar title="Confirm Transaction" canGoBack={true} />}
      >
        <div className="px-4">
          <p className="text-sgrey-4 stext-base ">To</p>
          <div className="py-3 space-y-4">
            <div className="bg-[#F2F4F7] p-3 rounded-lg">
              <p className="text-sgrey-1 text-lg font-myriadProSemibold">
                {state.data.recipient}
              </p>

              <div className="flex items-center justify-between space-x-3 text-sgrey-2 py-2">
                {isSKitUser ? (
                  <p>
                    Username:{" "}
                    <span className="font-myriadProSemibold">@{state.data.userName}</span>
                  </p>
                ) : (
                  <>
                    <p>{state.data.bank}</p>
                    <p>{state.data.accountNumber}</p>
                  </>
                )}
              </div>
            </div>
            <div className="bg-[#F2F4F7] p-3 rounded-lg">
              <p className="text-sgrey-2">Amount</p>
              <p className="text-sgrey-1 text-lg font-myriadProSemibold">
                {user.currency}{state.data.amount}
              </p>
              <div className="text-sgrey-1 stext-sm space-y-1 py-2">
                <p className="">
                  Equivalent amount ={" "}
                  <span className="font-myriadProSemibold">
                    {state.data.equivalentAmount}
                  </span>
                </p>
                <p className="">
                  Exchange rate ={" "}
                  <span className="font-myriadProSemibold">
                    {state.data.rate}
                  </span>
                </p>
              </div>
            </div>
            <div className="bg-[#F2F4F7] p-3 rounded-lg">
              <p className="text-sgrey-2">Description</p>
              <p className="text-sgrey-1 stext-base font-myriadProSemibold">
                {state.data.description}
              </p>
              <div className="text-sgrey-1 stext-sm space-y-2 py-3">
                {isSKitUser && (
                  <p className="flex justify-between">
                    Country:
                    <span className="font-myriadProSemibold">{state.data.country}</span>
                  </p>
                )}
                {/* <p className="flex justify-between">
                  Beneficiary Bank:
                  <span className="font-myriadProSemibold">
                    {state.data.bank}
                  </span>
                </p> */}
                <p className="flex justify-between">
                  Transaction fee:
                  <span className="font-myriadProSemibold">
                    {state.data.fee}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="py-3 flex items-center">
            <button
              className="p-3 rounded-full active:bg-slate-100"
              onClick={() => sethasAccepted(!hasAccepted)}
            >
              <div
                className={`h-4 w-4 grid place-items-center transition-all duration-300 ${
                  hasAccepted ? "bg-slilac" : "bg-disabled"
                } rounded`}
              >
                {hasAccepted && <IconTick />}
              </div>
            </button>
            <p className="text-sgrey-4 stext-sm">
              I agree to make this payment
            </p>
          </div>
          <div className="pb-3 max-w-[650px] mx-auto md:pt-6">
            <CustomButton
              title="Send Money"
              disabled={!hasAccepted}
              onClick={confirmPayment}
            />
          </div>
        </div>
      </Scaffold>
    </PageLayout>
  );
};

export default ConfirmTransaction;
