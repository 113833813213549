
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { IconBack } from 'utils/app_icons'
import useAppNavigator from 'utils/app_navigation'



/**
 * NavBar component that displays a title, leading and actions elements.
 * @param {object} props - The properties of the component
 * @param {string} [props.className] - The class name to apply to the component.
 * This classname will be used to apply styles to the NavBar
 * @param {string | JSX.Element} [props.title] - The title to display in the NavBar
 * @param {React.ReactNode} [props.actions] - The actions elements to render on the right side of the NavBar.
 * @param {boolean} [props.canGoBack] - If true, a back button will be rendered on the left side of the NavBar.
 * @param {React.ReactNode} [props.leading] - The leading elements to render on the left side of the NavBar.
 */
const NavBar = ({className, title, actions, canGoBack, leading}) => {
  const navigator = useAppNavigator()
  return (
    <div className={twMerge(`flex items-center justify-between h-[60px] stext-base px-4 w-full ${className}`)}>
      
        <div className='flex items-center space-x-2 text-sblack-600'>
          {canGoBack&&<button onClick={() => navigator.back()} className='w-9 h-9 grid place-items-center rounded-full active:bg-sgrey-4'><IconBack /></button>}
          {leading}
          <p className='font-myriadProSemibold text-[#191919] text-[18px]'>{title}</p>
        </div>
        <div className='flex items-center space-x-2'>
          {actions}
        {/* {actions.map((action, ind) => <div key={ind}>{action}</div>)} */}
        </div>

    </div>
  )
}

export default NavBar