// @ts-check

import React, { useState } from "react";
import {
  IconAddContainer,
  IconArrowDown,
  IconEyeClosed,
  IconEyeOpen,
  IconSendRound,
} from "utils/app_icons";
import card_decor from "assets/decor/card_decor.svg";
import ng_flag from "components/currency_converter/data/png100px/ng.png";
import setGlobalState, { useActions } from "global_store/useGlobalStore";
import { Reducers } from "global_store/reducers";
import useAppNavigator from "utils/app_navigation";
import AppRoutes from "router/route_paths";
import useLoginController from "pages/auth/login_page/login_controller";
import { formatCurrency } from "utils/functions";

/**
 * @typedef {object} Card
 * @property {boolean} isVisible
 */

/**
 * @type {Card}
 */
const initialState = { isVisible: true };
const { controllerAction, controllerReducer } = setGlobalState({
  name: "card",
  initialState,
});
export const cardReducer = controllerReducer;

const Card = ({ balance, minimized = false }) => {
  const user = useLoginController().user;
  const { state, setState } = useActions({
    controllerAction,
    reducer: Reducers.card,
  });
  const navigate = useAppNavigator();

  
  return (
    <div className="sm:bg-[#F4EBFF] sm:py-4 sm:pb-8 rounded-3xl relative z-0 grid place-items-center">
      <div className="w-full relative mt-6 sm:w-[420px] ">
        <div className="bg-slilac-100 h-[25px] rounded-xl absolute w-[80%]  center_item_absolute_x top-0 z-[-1]"></div>
        <div
          className={`card-bg w-full rounded-2xl ${
            minimized ? "h-[150px]" : "h-[200px] sm:h-[250px]"
          }  pb-4 pt-3 px-4 sm:pt-4 sm:pb-5 sm:px-6 text-white relative overflow-clip`}
        >
          <img
            src={card_decor}
            alt="card-decor"
            className="absolute right-0 top-0 h-full z-[1]"
          />
          <div className="relative z-[2] flex flex-col justify-between h-full pb-6">
            <div className="sm:space-y-5">
              <div
                className={`flex items-center relative z-10 justify-between ${
                  minimized && "pb-3"
                }`}
              >
                <div className="flex items-center">
                  <p className="stext-sm text-white ">Total Balance</p>
                  <button
                    onClick={() => {
                      setState({ state: "isVisible", value: !state.isVisible });
                      // setisBalanceVisible(!isBalanceVisible);
                      console.log("isBalanceVisible", state.isVisible);
                    }}
                    className="p-3 rounded-full"
                  >
                    {state.isVisible ? <IconEyeOpen /> : <IconEyeClosed />}
                  </button>
                </div>
                {minimized && <CountryDrop />}
              </div>
              <h1 className="stext-heading ">
                {user.currency}
                {state.isVisible ? formatCurrency(user.balance) : "0.00"}
             
              </h1>
            </div>
            {!minimized && (
              <div className="flex items-center justify-between space-x-3">
                <CountryDrop />
                <div className="flex items-center space-x-2 sm:space-x-4 ">
                  <button
                    className="flex items-center bg-[#7F25D8] px-2 pr-3 py-[0.4rem] rounded-xl stext-sm text-white space-x-[0.3rem] text-sSm sm:stext-base sm:text-white"
                    onClick={() => navigate.to(AppRoutes.sendMoney)}
                  >
                    <IconSendRound />
                    <p>Send money</p>
                  </button>
                  <button
                    className="flex items-center bg-[#7F25D8] px-2 pr-3 py-[0.4rem] rounded-xl stext-sm text-white space-x-[0.3rem] text-sSm sm:stext-base sm:text-white"
                    onClick={() => navigate.to(AppRoutes.depositMoney)}
                  >
                    <IconAddContainer />
                    <p>Deposit money</p>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;

const CountryDrop = () => {
  return (
    <div className="flex items-center space-x-2">
      <img src={ng_flag} alt="flag" className="w-6 h-4" />
      <IconArrowDown className="fill-white " />
    </div>
  );
};
