import { useEffect, useRef, useState } from "react";
import { IconStats } from "utils/app_icons";
// import { Bar } from "react-chartjs-2";
// import {
//   Chart,
//   Tooltip,
//   // Legend,
//   BarElement,
//   LinearScale,
//   CategoryScale,
// } from "chart.js";
import BarChart from "./bar_chart";

// Chart.register(Tooltip, BarElement, CategoryScale, LinearScale);

const HistoryCard = ({ data = [1, 4, 5, 2, 4, 2, 3, 3, 2, 4, 5, 7] }) => {
  const [isBarGraph, setisBarGraph] = useState(true);
  const isThisMonth = new Date(Date.now()).getMonth();
  const colors = data.map((dat, ind) =>
    ind === isThisMonth ? "#E9D7FE" : "#9E77ED"
  );
  useEffect(() => {
    setdotCount(divRef.current.offsetWidth / dotWidth - 3);
  }, []);

  /**
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const divRef = useRef(null);
  const [dotCount, setdotCount] = useState(0);
  const dotWidth = 5;
  console.log(dotCount);
  return (
    <div className="shadow-[0px_32px_30px_5px_rgba(108,44,180,0.4)] rounded-2xl  py-7 text-white bg-sprimary-80 ">
      <div className="flex justify-between items-center space-x-3 px-6">
        <h1 className="text-xl font-myriadProSemibold">₦1,800,400.00</h1>
        <button
          className="h-9 w-9 bg-[#7F25D8] rounded-lg grid place-items-center"
          onClick={() => setisBarGraph(!isBarGraph)}
        >
          <IconStats />
        </button>
      </div>
      <div className="px-5 pt-3 ">
        <div className="flex items-center w-full space-x-[2px]" ref={divRef}>
          <div className="flex space-x-[1px]">
            {Array.from(Array(parseInt(dotCount)).keys()).map((ind) => (
              <div key={ind} className="h-[1px] w-[4px] bg-white"></div>
            ))}
          </div>
          <p className="text-[5px]">Max</p>
        </div>
      </div>
      <div className="h-[70px] px-6 grid place-items-center rounded-xl mt-5">
        {isBarGraph ? (
          <BarChart
            data={{
              labels: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
              datasets: {
                data: data,
                barColors: colors,
              },
            }}
          />
        ) : (
          <h2>Line Graph</h2>
        )}
      </div>
    </div>
  );
};

export default HistoryCard;
