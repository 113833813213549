import NavBar from "components/layout/nav_bar";
import PageLayout from "components/layout/page_layout";
import Scaffold from "components/layout/scaffold";
import React from "react";
import AppRoutes from "router/route_paths";
import { IconBank, IconProfile } from "utils/app_icons";
import useAppNavigator from "utils/app_navigation";
import PaymentItem from "../main_activity/payment/sections/payment_item";

const SendMoney = () => {
  const navigate = useAppNavigator();
  return (
    <Scaffold appBar={<NavBar title="Send Money" canGoBack={true} />}>
      <PageLayout animationType="fadeIn">
        <div className="py-3 space-y-4 px-4">
          <PaymentItem
            onPressed={() => navigate.to(AppRoutes.sendToBank)}
            icon={<IconBank />}
            title="Send to Bank"
            subTitle="Send money directly to a receipient’s bank"
          />
          <PaymentItem
            onPressed={() => navigate.to(AppRoutes.sendToSkitppayUser)}
            icon={<IconProfile className="text-white" />}
            title="Send to Skitpay User"
            subTitle="Send money directly to a via a skitpay username"
          />
        </div>
      </PageLayout>
    </Scaffold>
  );
};

export default SendMoney;
