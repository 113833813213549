import CustomCountryDropDown from "./custom_country_dropdown";

const { useState, useEffect } = require("react");

const ConverterItem = ({
  text,
  isDiasbled = false,
  initialCountry,
  initialValue = "",
  onValuesChanged,
  refetch,
}) => {
  const [value, setvalue] = useState(initialValue);
  useEffect(() => {
    setvalue(initialValue);
  }, [initialValue]);

  return (
    <div className="w-full flex justify-between border border-[#d1adfd] ring-4 ring-[#F4EBFF] rounded-lg px-5 py-1 items-center focus-within:ring-[7px] focus-within:ring-[#e7d7fc] transition-all duration-500">
      <div className="flex flex-col items-start">
        <p className="stext-base text-[0.75rem]">{text}</p>
        <input
          className="w-full stext-heading outline-none bg-transparent font-medium text-[1.3rem]"
          type="number"
          value={value}
          disabled={isDiasbled}
          onChange={(event) => {
            console.log("something is changing");
            setvalue(event.target.value);
            onValuesChanged({ value: event.target.value });
          }}
        />
      </div>
      <CustomCountryDropDown
        initialCountry={initialCountry}
        onCountryChanged={(country) => onValuesChanged({ country: country })}
      />
    </div>
  );
};

export default ConverterItem;
