import React from "react";

import usePaymentController from "../payment_controller";
import PaymentItem from "./payment_item";

const PaymentOptions = () => {
  const controller = usePaymentController();
  return (
    <div className="pt-7 space-y-4">
      {controller.paymentItems.map((paymentItem) => {
        return (
          <PaymentItem
            key={paymentItem.title}
            icon={paymentItem.icon}
            onPressed={paymentItem.onPressed}
            subTitle={paymentItem.subTitle}
            title={paymentItem.title}
          />
        );
      })}
    </div>
  );
};

export default PaymentOptions;
