import { TransctionType } from "components/transaction_item";
import { dummyTransactions } from "data/dummyData";
import TransactionModel from "models/transaction_model";
import React, { useEffect, useState } from "react";
import useHistoryController from "../history/history_controller";

const useHomeController = () => {
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    getTransactions();
  }, []);


  const history = useHistoryController()
  
  // const transactions = [
    // new TransactionModel({
    //   name: "John Fonesca",
    //   amount: "70,000",
    //   date: new Date(Date.now()),
    //   type: TransctionType.debit,
    // }),
    // new TransactionModel({
    //   name: "Andrea Fonesca",
    //   amount: "20,000",
    //   date: new Date(Date.now()),
    //   type: TransctionType.credit,
    // }),
    // new TransactionModel({
    //   name: "Fabs Kiriod",
    //   amount: "42,000",
    //   date: new Date(Date.now()),
    //   type: TransctionType.credit,
    // }),
  // ];

  const transactions = history.state.transactions

  const getTransactions = async () => {
    setisLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setisLoading(false);
  };
  return { transactions, isLoading };
};

export default useHomeController;
