import { motion } from "framer-motion";

/**
 * @typedef {object} PageAnimationType
 * @property {string} slideIn
 * @property {string} fadeIn
 */

/**
 * @type {PageAnimationType}
 */

/**
 * 
 * @param {object} props 
 * @param {JSX.Element} props.children
 * @param {'slideIn' | 'fadeIn' | 'none'=} props.animationType

 */
const PageLayout = ({ children, animationType = "fadeIn" }) => {
  if (animationType === "slideIn") {
    return (
      <motion.div
        initial={{
          x: "100vw",
          // opacity:0
        }}
        animate={{
          // opacity: 1,

          x: 0,
        }}
        exit={{
          // opacity: 0,
          x: "-100vw",
        }}
        transition={{
          type: "spring",
          mass: 0.2,
        }}
        className="w-full h-full"
      >
        {children}
      </motion.div>
    );
  } else if (animationType === "fadeIn") {
    return (
      <motion.div
        initial={{
          // x: "100vw",
          opacity: 0,
        }}
        animate={{
          opacity: 1,

          // x: 0,
        }}
        exit={{
          opacity: 0,
          // x: "-100vw",
        }}
        transition={{
          type: "spring",
          mass: 0.2,
        }}
        className="w-full h-full"
      >
        {children}
      </motion.div>
    );
  } else {
    return <div className="w-full h-full">{children}</div>;
  }
};

export default PageLayout;
