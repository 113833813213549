import useLoginController from "pages/auth/login_page/login_controller";
import { Navigate } from "react-router-dom";
import AppRoutes from "router/route_paths";

const ProtectedRoute = ({ element }) => {
  const { isLoggedIn } = useLoginController();
  // console.log(isLoggedIn, "Protected Route");

  return (
    <>
      {/* {<Navigate to={AppRoutes.logIn} /> }</> */}
      {isLoggedIn ? element : <Navigate to={AppRoutes.logIn} />}
    </>
  );
};

export default ProtectedRoute;
