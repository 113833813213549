/**
 *
 * @param {Date} date
 * @param {boolean} extra
 * @returns {string}
 */

export const formatDate = (date, addToday = true) => {
  const options = { day: "numeric", month: "short", year: "numeric" };
  const result = date.toLocaleDateString("en-US", options);
  const dates = result.replace(",", "").split(" ");
  const day = `${dates[1]}${getOrdinal(dates[1])}`;
  const month = dates[0];
  const year = dates[2];

  if (addToday && date.toDateString() === new Date(Date.now()).toDateString()) {
    return "Today";
  }

  return `${day} ${month}. ${year}`;
};

/**
 *
 * @param {number} day
 */
const getOrdinal = (day) => {
  if (day.charAt(day.length - 1) === "1") {
    return "st";
  } else if (day.charAt(day.length - 1) === "2") {
    return "nd";
  } else if (day.charAt(day.length - 1) === "3") {
    return "rd";
  }
  return "th";
};

/**
 *
 * @param {Date} date
 * @returns {string}
 */
export const formatTime = (date) => {
  let options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "Africa/Lagos",
  };
  return `${date.toLocaleTimeString("en-US", options).toLowerCase()} WAT`;
};

/**
 *
 * @param {number} value
 * @returns {string}
 */
export const formatCurrency = (value) => {
  return value
    .toFixed()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

// const formatNumber = () => {
//   return new Intl.NumberFormat("en-IN", {
//     style: "currency",
//     currency: "INR",
//   }).format(3000);
// };

export const generateTransRef = () => {
  const hex = "0123456789ABCDEF";
  const len = 11;
  let output = "";
  for (let i = 0; i < len; ++i) {
    output += hex.charAt(Math.floor(Math.random() * hex.length));
  }
  return output;
};
