import NavBar from "components/layout/nav_bar";
import Scaffold from "components/layout/scaffold";
import React from "react";
import Contacts from "./sections/contacts";
import PaymentOptions from "./sections/payment_options";

const Payment = () => {
  return (
    <Scaffold
      
      appBar={<NavBar title="Payments" />}
      inset
    >
      <div className="px-4">
      <Contacts />
      <PaymentOptions />
      </div>
    </Scaffold>
  );
};

export default Payment;
