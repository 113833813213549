import React from "react";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
const PopUpModal = ({
  isShown = false,
  setIsShown,
  message,
  subMessage,
  type = PopUpModalType.failed,
}) => {
  console.log(subMessage);
  return (
    <AnimatePresence mode="wait">
      {isShown && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{
            opacity: 0,
          }}
          onClick={() => setIsShown(false)}
          className="bg-[rgb(0,0,0,0.5)] fixed z-[1000] w-screen h-screen flex justify-center items-center"
        >
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1, transition: { delay: 0.5 } }}
            exit={{ scale: 0.4 }}
            className="bg-white shadow-2xl  w-[85%] max-w-[600px] rounded-2xl px-4 py-10  flex justify-center items-center flex-col"
          >
            <div className="w-[150px] h-[150px] flex justify-center items-center text-center bg-slate-100 ">
              <p> svg place holder</p>
            </div>
            <h2 className="text-[20px] py-4 ">{message ?? "Oops!"}</h2>
            <p className="text-sSm text-sgrey-2">
              {subMessage ??
                "Something went wrong. Kindly check your network connection or the details you inputed"}
            </p>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

PopUpModal.propTypes = {
  isShown: PropTypes.bool,
  setIsShown: PropTypes.func,
  message: PropTypes.string,
  subMessage: PropTypes.string,
  type: PropTypes.string,
};

export const PopUpModalType = {
  success: "success",
  failed: "failed",
};

export default PopUpModal;
