// @ts-check

import CustomButton from "components/button";
import Card from "components/card";
import CustomForm from "components/form/custom_form";
import CustomInput from "components/form/custom_input";
import useFormState from "components/form/form_state";
import NavBar from "components/layout/nav_bar";
import PageLayout from "components/layout/page_layout";
import Scaffold from "components/layout/scaffold";
import React from "react";
import useSendToSkitContoller from "./send_to_skitpayuser_controller";
import { motion } from "framer-motion";
import { TransactionType } from "enums";
import FormVaidation from "utils/form_validation";
import { ToastContainer } from "react-toastify";
import useLoginController from "pages/auth/login_page/login_controller";

const SendToSkitUser = () => {
  const controller = useSendToSkitContoller();
  const balance = useLoginController().user.balance;

  const userFound = controller.recipient.data !== null;
  return (
    <PageLayout>
      <Scaffold
        appBar={<NavBar title="Send to Skitpay User" canGoBack={true} />}
      >
        <div className=" space-y-4 px-4">
          <ToastContainer />

          <div className="sticky top-0 pb-2 bg-white">
            <Card balance="3,010,830.00" minimized />
          </div>
          <CustomForm
            formStateProps={controller.formprops}
            className="pt-2 pb-3 space-y-2"
          >
            {userFound && (
              // <motion.div
              //   initial={{
              //     opacity: 0,
              //   }}
              //   animate={{ opacity: 1 }}
              // >
              <CustomInput
                name="name"
                title="Name"
                disabled={true}
                value={`${controller.recipient.data.lastName} ${controller.recipient.data.firstName}`}
              />
              // </motion.div>
            )}

            <CustomInput
              name="username"
              title="Username"
              validator={FormVaidation.validateSingleName}
            />

            <CustomInput
              name="amount"
              title="Amount"
              validator={(value) => {
                if (balance < parseInt(value)) {
                  return "Amount to send is greater than availabe balance";
                }
                return FormVaidation.validateAmount(value);
              }}
              type="number"
              onChanged={(e) => controller.getEquivalentAmount(e.target.value)}
            />

            {userFound && (
              <p className="flex justify-start text-sm text-[#667085] font-myriadProRegular">
                {controller.isGettingAmount
                  ? "...."
                  : controller.equivalentAmount.text}{" "}
              </p>
            )}
            {userFound && (
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{ opacity: 1 }}
              >
                <CustomInput
                  name="country"
                  title="Country"
                  value={controller.recipient.data.country}
                  disabled={true}
                />
              </motion.div>
            )}

            <CustomInput
              name="note"
              title="Note"
              inputType="textarea"
              // @ts-ignore
              rows={2}
            />
          </CustomForm>
          <div className="pb-3 max-w-[650px] mx-auto">
            <CustomButton
              isLoading={controller.isLoading}
              title={userFound ? "Continue" : "Find User"}
              onClick={() =>
                userFound
                  ? controller.continuePayment()
                  : controller.findRecipient()
              }
            />
          </div>
        </div>
      </Scaffold>
    </PageLayout>
  );
};

export default SendToSkitUser;
