import React from "react";
import AppRoutes from "router/route_paths";
import {
	IconBank,
	IconRecievePayment,
	IconRecieveScheduledPayment,
	IconSend,
	IconSwap
} from "utils/app_icons";
import useAppNavigator from "utils/app_navigation";

const usePaymentController = () => {
	const navigate = useAppNavigator();
	const paymentItems = [
		{
			icon: <IconSend />,
			title: "Send Money",
			subTitle: "Transfer money to anyone",
			onPressed: () => navigate.to(AppRoutes.sendMoney)
		},
		{
			icon: <IconBank />,
			title: "Deposit Money",
			subTitle: "Deposit money into your account",
			onPressed: () => navigate.to(AppRoutes.depositMoney)
		},
		{
			icon: <IconRecieveScheduledPayment />,
			title: "Schedule Payment",
			subTitle: "Schedule payments anytime  Coming soon",
			onPressed: null
		},
		{
			icon: <IconSwap />,
			title: "Swap Money",
			subTitle: "Transfer money to anyone  Coming soon",
			onPressed: null
		},
		{
			icon: <IconRecievePayment />,
			title: "Pay Bills & Subscriptions",
			subTitle: "Transfer money to anyone  Coming soon",
			onPressed: null
		}
	];
	return { paymentItems };
};

export default usePaymentController;
