import { useEffect, useState } from "react";

const useMediaQuery = () => {
	const [deviceSize, setdeviceSize] = useState({ height: 0, width: 0 });

	useEffect(() => {
		const handleResize = () => {
			setdeviceSize({
				height: Math.max(
					document.documentElement.clientHeight,
					window.innerHeight || 0
				),
				width: Math.max(
					document.documentElement.clientWidth,
					window.innerWidth || 0
				)
			});
		};
		window.addEventListener("resize", handleResize);

		handleResize();
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return deviceSize;
};

export default useMediaQuery;
