import CustomInput from "components/form/custom_input";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { IconArrowDown } from "utils/app_icons";

/**
 *
 * @param {object} props
 * @param {string=} props.className
 * @param {string=} props.titleStyle
 * @param {{name: string, logo?: string}} props.initialValue
 * @param {JSX.IntrinsicElements=} props.otherContent
 * @param {Array<{name: string, logo?: string}>=} props.data
 * @param {Array<React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>>=} props.dataWithContent
 * @param {string=} props.title
 * @param {string=} props.placeholder
 * @param {string=} props.name
 * @param {any=} props.formprops
 * @param {()=>{}=} props.validator
 * @param {(string)=>any=} props.onChanged
 * @param {()=>any=} props.onItemSelected
 * @param {boolean=} props.reverseTrailing
 * @param {boolean=} props.readOnly
 * @param {boolean=} props.isLoading
 */

const CustomDropDown = ({
  className,
  initialValue = {},
  titleStyle,
  title,
  otherContent,
  data,
  placeholder,
  dataWithContent,
  name,
  formprops,
  onChanged = (e) => {},
  onItemSelected = (e) => {},
  validator,
  reverseTrailing = false,
  readOnly = true,
  isLoading = false,
}) => {
  const [isActive, setisActive] = useState(false);
  const [currentValue, setcurrentValue] = useState(initialValue);
  var filteredData = [];
  data.forEach((filtr) => {
    if (currentValue.name) {
      if (
        filtr.name
          .toLocaleLowerCase()
          .includes(currentValue.name.toLocaleLowerCase())
      ) {
        filteredData.push(filtr);
      }
    } else {
      filteredData = data;
    }
  });

  return (
    <div className="relative cursor-pointer">
      <CustomInput
        name={name}
        title={title}
        reverseTrailing={reverseTrailing}
        placeholder={placeholder}
        onFocus={isLoading ? () => {} : () => setisActive(true)}
        onBlur={isLoading ? () => {} : () => setisActive(false)}
        formprops={formprops}
        value={currentValue.name}
        onChanged={(e) => {
          const flagData = data.find(
            (ele) =>
              ele.name.toLowerCase() === e.target.value.toLocaleLowerCase()
          );
          setcurrentValue({
            name: e.target.value,
            logo: flagData !== undefined ? flagData.logo : "",
          });
          onChanged(e);
        }}
        className="cursor-pointer"
        autoComplete={false}
        // readOnly={readOnly}
        validator={validator}
        trailing={
          <div className="flex items-center space-x-2">
            {isLoading ? (
              <div className="h-[20px] w-[20px] border-2 rounded-full border-sgrey-3 border-r-transparent animate-spin"></div>
            ) : (
              <>
                {currentValue.logo && (
                  <img src={currentValue.logo} alt="" className="w-[30px]" />
                )}
                <IconArrowDown
                  className={`transition-all duration-500 cursor-pointer ${
                    isActive && "rotate-180"
                  }`}
                />
              </>
            )}
          </div>
        }
      />

      {filteredData.length >= 1 && (
        <DropDownModal
          isActive={isActive}
          data={filteredData}
          dataWithContent={dataWithContent}
          setValue={setcurrentValue}
          onItemSelected={onItemSelected}
          setIsActive={setisActive}
          title={title}
          name={name}
          formprops={formprops}
        />
      )}
    </div>
  );

  // return (
  //   <div
  //     className={`flex flex-col items-start ${className}`}
  //     // onClick={() => inputRef.current.focus()}
  //   >
  //     {title && (
  //       <p
  //         className={`text-sSm md:text-[14px] font-semibold text-sgrey-1 ${titleStyle}`}
  //       >
  //         {title}
  //       </p>
  //     )}
  //     <div className="relative w-full">
  //       <div
  //         // className="border-2 focus-within:border-red-900 relative flex justify-between items-center h-[40px]"
  //         className={`w-full h-[40px] transition-all flex justify-between items-center px-3  border border-sgrey-4 border-opacity-75 rounded-lg  `}
  //       >

  //         {/* <input
  //           className="outline-none absolute bg-transparent w-full h-full left-0 cursor-pointer pl-3 pr-5"
  //           value={currentValue}
  //           placeholder={placeholder}
  //           onFocus={() => setisActive(true)}
  //           onBlur={() => setisActive(false)}
  //           readOnly
  //           ref={inputRef}
  //         /> */}
  //         <div></div>
  //         {/* <p>{currentValue}</p> */}
  //         <div className="flex space-x-1">
  //           <>{otherContent}</>
  //           <IconArrowDown
  //             className={` transition-all duration-300 ${
  //               isActive ? "rotate-180" : ""
  //             }`}
  //           />
  //         </div>
  //       </div>
  //       {/* Modal */}
  //       <DropDownModal
  //         isActive={isActive}
  //         data={data}
  //         dataWithContent={dataWithContent}
  //         setValue={setcurrentValue}
  //         setIsActive={setisActive}
  //         title={title}
  //       />
  //     </div>
  //   </div>
  // );
};

export default CustomDropDown;

const DropDownModal = ({
  isActive,
  onItemSelected,
  data = [],
  dataWithContent,
  setValue,
  setIsActive,
  title,
  name,
  formprops,
}) => {
  return (
    <AnimatePresence>
      {isActive && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          className="max-h-[200px] overflow-y-auto border bg-white absolute top-[65px] left-0 w-full rounded-lg max-w-[400px] px-2 py-2 space-y-2 z-10"
        >
          <div>
            {/* {data.length > 7 && (
              <input
                className="border"
                onFocus={() => setIsActive(true)}
                onBlur={() => setIsActive(false)}
                placeholder={`serach ${title.toLowerCase()}`}
              />
            )} */}
            {/* {dataWithContent == null
              ? data.length > 0 &&
                data.map((data) => (
                  <p
                    key={data}
                    className="px-3 py-2 cursor-pointer rounded-md hover:bg-sgrey-4"
                    onClick={() => {
                      setValue(data);

                      formprops.handleChange({
                        target: { name, value: data },
                        persist: () => {},
                      });
                    }}
                  >
                    {data}
                  </p>
                ))
              : dataWithContent.map((data) => data)} */}
            {data.length > 0 &&
              data.map((dData) => (
                <div
                  key={dData.name}
                  className="flex items-center space-x-4 cursor-pointer rounded-md hover:bg-sgrey-5 px-3 py-2"
                  onClick={() => {
                    setValue(dData);
                    onItemSelected(dData);
                    formprops.handleChange({
                      target: { name, value: dData.name },
                      persist: () => {},
                    });
                  }}
                >
                  {dData.logo && (
                    <img
                      src={dData.logo}
                      alt=""
                      className="w-[30px] h-[20px]"
                    />
                  )}
                  <p className=" ">{dData.name}</p>
                </div>
              ))}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
