import CustomButton from "components/button";
import CustomForm from "components/form/custom_form";
import CustomInput from "components/form/custom_input";
import useFormState from "components/form/form_state";
import CustomDropDown from "components/input/custom_dropdown";
import NavBar from "components/layout/nav_bar";
import PageLayout from "components/layout/page_layout";
import Scaffold from "components/layout/scaffold";
import React from "react";
import { IconAddContainer, IconCardDecor, IconChip } from "utils/app_icons";

const CreditDebitCard = () => {
  const formprops = useFormState();
  return (
    <Scaffold appBar={<NavBar title="Credit or Debit Card" canGoBack={true} />}>
      <PageLayout animationType="fadeIn">
        <div className="py-3 space-y-4 px-4">
          <div className="pb-3">
            <CreditDebitCardItem />
          </div>
          <div className="flex space-x-2">
            <div className="w-full bg-[#ECE8FD] border border-[#8567E7] h-[40px] grid place-items-center rounded-lg text-sblack-600 text-sm font-myriadProSemibold">
              Debit card
            </div>
            <div className="w-full bg-[#FEE4E2] border border-[#F97066] h-[40px] grid place-items-center rounded-lg text-sblack-600 text-sm font-myriadProSemibold">
              Credit card
            </div>
          </div>
          <CustomForm
            formStateProps={formprops}
            className="pt-2 pb-3 space-y-3"
          >
            <CustomInput
              name="cardholder-name"
              title="Cardholder name"
              // props={{ type: "number" }}
            />
            <CustomDropDown
              name="card-type"
              title="Card type"
              data={["Master Card", "Debit Card"]}
            />
            <CustomInput
              name="card-number"
              title="Card number"
              // props={{ type: "number" }}
            />

            <div className="flex space-x-4">
              <CustomInput
                name="exp-date"
                title="Expiry date"
                props={{ type: "number" }}
              />
              <CustomInput name="cvv" title="CVV" />
            </div>
            <CustomInput
              name="amount"
              title="Amount"
              // props={{ type: "number" }}
            />
          </CustomForm>
          <div className="flex space-x-2">
            <CustomButton title="Save card" disabled={true} />
            <CustomButton title="Continue" />
          </div>
        </div>
      </PageLayout>
    </Scaffold>
  );
};

export default CreditDebitCard;

const CreditDebitCardItem = () => {
  return (
    <div className="card-bg rounded-2xl active:scale-[0.97] transition-all text-white px-6 py-7">
      <div className="flex justify-between items-center">
        <div className="flex space-x-3 items-center">
          <IconChip />
          <p className="font-myriadProSemibold"> Debit Card </p>
        </div>
        <IconCardDecor />
      </div>
      {/* <div className="flex justify-center items-center space-x-2 text-2xl font-myriadProSemibold pt-4">
        <span className="leading-0">+</span>
        <p>Use Card</p>
      </div> */}
      <div>
        <p className="text-2xl pb-2 pt-10 text-center font-myriadProSemibold">
          4563 5672 4839 2362
        </p>
        <div className="w-full h-[1px] bg-white mb-6 opacity-70"></div>
      </div>
      <div className="flex space-x-3 justify-between items-center">
        <p>John Carter</p>
        <p>02/30</p>
      </div>
    </div>
  );
};
