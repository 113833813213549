import useFormState from "components/form/form_state";
import {
  curencyShortcodetoSymbol,
  dummyConversions,
  dummyUsers,
  dummysupportedCountries,
} from "data/dummyData";
import { TransactionType } from "enums";
import useLoginController from "pages/auth/login_page/login_controller";
import { useState } from "react";
import { toast } from "react-toastify";
import AppRoutes from "router/route_paths";
import useAppNavigator from "utils/app_navigation";
import FormVaidation from "utils/form_validation";

const useSendToSkitContoller = () => {
  const [isLoading, setisLoading] = useState(false);
  // const [userFound, setuserFound] = useState(true);
  /**
   * @type {[{message: string, data: {  email: string, password: string, id: number,   firstName: string, lastName: string, balance: number, userName: string, country:string }}, (){}]}
   */
  const [recipient, setrecipient] = useState({ data: null });
  /**
   * @type {[equivalentAmount: {value: string, text: string, isOk: Boolean, rate: string }, setequivalentAmount: ({value: number, text: string, isOk: Boolean, rate: string },)=>{}]}
   */
  const [equivalentAmount, setequivalentAmount] = useState({});
  const [isGettingAmount, setisGettingAmount] = useState(false);

  const formprops = useFormState();
  const navigate = useAppNavigator();

  const lController = useLoginController();
  const userShortCode = lController.user.country.shortCode;

  const findRecipient = async () => {
    if (!formprops.validateForm()) {
      return;
    }
    const userName = formprops.values["username"];
    setisLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const user = dummyUsers.find((user) => user.userName === userName);
    if (user === undefined) {
      setrecipient({ message: "could not find user", data: null });
      toast.error("Could not find user");
      setisLoading(false);
      return;
    }
    setrecipient({ message: "user found", data: user });
    setisLoading(false);
    getEquivalentAmount(formprops.values["amount"], user.country);
  };

  const getEquivalentAmount = async (amount, dcountry = null) => {
    if (FormVaidation.validateAmount(amount) !== null) {
      console.log("The Amount is not valid");
      setequivalentAmount({});
      return;
    }

    setisGettingAmount(true);
    const compCount =
      dcountry !== null
        ? dcountry
        : recipient.data !== null
        ? recipient.data.country
        : null;
    const toCountry = dummysupportedCountries.find(
      (country) => country.name === compCount
    );
    if (toCountry === undefined) {
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, 2000));
    setisGettingAmount(false);

    const conversionMap = dummyConversions.find((conversion) => {
      return (
        conversion.countries.includes(toCountry.shortCode) &&
        conversion.countries.includes(userShortCode)
      );
    });

    // const theRate = Object.values(dummyConversions).find(
    //   (rate) => rate.from === "NGN" && rate.to === toCountry.shortCode
    // );
    const theRate =
      userShortCode === toCountry.shortCode
        ? 1
        : conversionMap.countries[0] === userShortCode
        ? conversionMap.value
        : 1 / conversionMap.value;
    const rate = theRate * amount;
    setequivalentAmount({
      text: `The equivalent amount is = ${
        curencyShortcodetoSymbol[toCountry.shortCode]
      }${rate.toFixed(2)}`,
      value: `${curencyShortcodetoSymbol[toCountry.shortCode]}${rate.toFixed(
        2
      )}`,
      rate: `1${userShortCode} / ${rate.toFixed(2)}${toCountry.shortCode}`,
      isOk: true,
    });
  };

  const continuePayment = async () => {
    const fee = `${curencyShortcodetoSymbol[userShortCode]}${(
      (0.99 / 100) *
      +formprops.values["amount"]
    ).toFixed(1)}`;
    navigate.to(AppRoutes.confirmTransaction, {
      state: {
        type: TransactionType.skitUser,
        data: {
          recipient: recipient.data.userName,
          userName: recipient.data.userName,
          country: recipient.data.country,
          // bank: formprops.values["bank"],
          // accountNumber: formprops.values["account-num"],
          amount: formprops.values["amount"],
          equivalentAmount: equivalentAmount.value,
          rate: equivalentAmount.rate,
          description: formprops.values["note"],
          fee: fee,
        },
      },
    });
  };
  return {
    findRecipient,
    isLoading,
    continuePayment,
    getEquivalentAmount,
    isGettingAmount,
    recipient,
    formprops,
    equivalentAmount,
  };
};

export default useSendToSkitContoller;
