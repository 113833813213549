import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const Dialogue = ({ components, closeModal }) => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={closeModal}
        className="bg-black bg-opacity-20 fixed w-full h-full top-0 left-0"
      ></motion.div>
      <motion.div
        initial={{ scale: 0, y: '-50%', x:'-50%' }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}
        transition={{
          bounce: 0,
        }}
        className="fixed w-full top-[50%] left-[50%] grid place-items-center"
      >
        {components}
      </motion.div>
    </>
  );
};

export default Dialogue;
