import useLoginController from "pages/auth/login_page/login_controller";
import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppRoutes from "router/route_paths";
import useAppNavigator from "utils/app_navigation";
import useAppNotifications from "./notifications/use_app_notifications";

const timeOut = 50 * 60 * 1000;
const excludedRoutes = [AppRoutes.logIn, AppRoutes.signUp, AppRoutes.otp];

const InactivityTimer = ({ children }) => {
  const expTime = "expTime";
  const timer = useRef();
  const location = useLocation();
  const navigate = useAppNavigator();
  const notificication = useAppNotifications();
  const lController = useLoginController();
  useEffect(() => {
    localStorage.setItem(expTime, Date.now() + timeOut);
    timer.current = setInterval(logOut, 1000);

    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("keydown", resetTimer);

    return () => {
      clearInterval(timer.current);
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("keydown", resetTimer);
    };
  }, []);

  const resetTimer = () => {
    localStorage.setItem(expTime, Date.now() + timeOut);
  };

  const logOut = async () => {
    console.log("trying  Logged out");
    const exprTime = localStorage.getItem(expTime);
    if (exprTime > Date.now()) {
      return;
    }
    if (excludedRoutes.includes(location.pathname)) {
      console.log("Not Logging out");
      return;
    }
    console.log("Logging out");
    await new Promise((resolve) => setTimeout(resolve, 2000));
    console.log("Showing Notifs");
    lController.logOut();
    // notificication.showDialogue("logout", {
    //   component: (
    //     <div className="px-3 py-6 rounded-3xl bg-white shadow-lg w-[300px]">
    //       <h2>Were So sorry</h2>
    //       <p>Your session has come to an end, please do well too login again</p>
    //     </div>
    //   ),
    // });
    // Perform the logout action here
  };

  return <>{children}</>;
};

export default InactivityTimer;
