import CustomButton from "components/button";
import CustomForm from "components/form/custom_form";
import useFormState from "components/form/form_state";
import CustomInput from "components/form/custom_input";
import FormVaidation from "utils/form_validation";
import { useEffect } from "react";
import useAppNavigator from "utils/app_navigation";
import useSignUpController from "../signup_controller";
import AppRoutes from "router/route_paths";
import CustomDropDown from "components/input/custom_dropdown";

const SignUpEssentialDetails = () => {
  const formStateProps = useFormState();
  const { validateForm, isFormValid } = formStateProps;
  const navigate = useAppNavigator();
  const controller = useSignUpController();

  useEffect(() => {
    if (Object.keys(controller.state.userData).length <= 0) {
      navigate.to(AppRoutes.signUp);
    }
  }, []);

  // function preventDefaultBack() {
  //   console.log(controller.state.isInit, 'Is Init');

  //   if (!controller.state.isInit) {
  //     console.log('Is In FFirst Init');
  //     // controller.setState({ state: "isInit", value: true });
  //     window.history.pushState(null, null, window.location.href);
  //     window.onpopstate = function () {
  //       console.log(controller.state.isInit, 'Is Init pop');
  //       // window.history.pushState(null,null, window.location.href);
  //       console.log("In Pop State");
  //       // window.history.back()
  //       navigatePage(0);
  //     };
  //   }
  // }

  const handleFormSubmit = async () => {
    if (validateForm()) {
      console.log("Valdated");
      controller.signUpUser();
    } else {
      console.log("Not validated");
    }
  };
  return (
    <div className="slide_in_rtl w-full my-4 px-5 h-full overflow-y-auto">
      <CustomForm formStateProps={formStateProps} className="space-y-3 pb-8">
        <CustomInput
          type={"email"}
          name="email"
          title="Email"
          value={controller.state.userData.email}
          onChanged={controller.addstate}
          validator={FormVaidation.validateEmail}
          // validator={FormVaidation.validateEmail}
        />
        <CustomInput
          // type={"number"}
          name="country"
          title="Country"
          value={controller.state.userData.country}
          onChanged={controller.addstate}
          validator={FormVaidation.validatePhone}
        />
        {/* <CustomDropDown
          title="Phone number"
          data={["Nigeria"]}
          name="country"
          reverseTrailing={true}
        /> */}

        <CustomInput
          type={"password"}
          name="password"
          title="Password"
          value={controller.state.userData.password}
          onChanged={controller.addstate}
          validator={FormVaidation.validatePassword}
        />
        <CustomInput
          type={"password"}
          name="passwordConfirm"
          title="Confirm Password"
          value={controller.state.userData.passwordConfirm}
          onChanged={controller.addstate}
          validator={FormVaidation.validatePassword}
        />
      </CustomForm>

      <p className={` font-normal text-[12px] pb-4 text-sgrey-2`}>
        By clicking continue you agree to Skitpay terms of privacy use
      </p>
      <CustomButton
        title="Continue"
        disabled={!isFormValid}
        onClick={handleFormSubmit}
        isLoading={controller.state.isLoading}
      />
    </div>
  );
};

export default SignUpEssentialDetails;
