// class AppRoutes  {
//  static welcome = '/welcome'
//  static signUp = '/signup'
//  static logIn = '/login'
//  static otp = '/otp'
//  static mainActivity = '/main_activity/'
//  static home = `${this.mainActivity}home`
//  static depositMoney = `${this.mainActivity}deposit-money`
//  static creditDebit = `${this.mainActivity}credit-debit`
// }

// export default AppRoutes

class AppRoutes {
  static welcome = "/welcome";
  static signUp = "/signup";
  static logIn = "/login";
  static otp = "/otp";
  static mainActivity = "/";
  static sendMoney = "/send-money/";
  static sendToBank = `${this.sendMoney}bank`;
  static sendToSkitppayUser = `${this.sendMoney}user`;
  static confirmTransaction = `${this.sendMoney}confirm`;
  static authorizetransaction = `${this.sendMoney}authorize`;
  static depositMoney = `/deposit-money/`;
  static creditDebit = `${this.depositMoney}credit-debit`;
  static bankTransfer = `${this.depositMoney}bank`;
  static account = `/account/`;
  static editProfile = `/edit_profile/`;
}

export default AppRoutes;
