import CustomButton from "components/button";
import OtpField from "components/input/otp_field";
import NavBar from "components/layout/nav_bar";
import PageLayout from "components/layout/page_layout";
import Scaffold from "components/layout/scaffold";
import TransactionRecieptModal from "components/notifications/transaction_reciept_modal";
import TransactionStatusModal from "components/notifications/transaction_status";
import React from "react";
import useAuthorizeTransaction from "./authorize_transaction_controller";
import { ToastContainer } from "react-toastify";
const AuthorizeTransaction = () => {
  const controller = useAuthorizeTransaction();
  return (
    <PageLayout>
      <Scaffold
        appBar={
          controller.successModal.isModalShown ? null : (
            <NavBar title="Confirm Details" canGoBack={true} />
          )
        }
      >
        <div className="px-4 tb:pt-[80px] w-full h-full relative">
          {/* <div className="bg-red-400 w-full h-full absolute top-0"></div> */}
          <ToastContainer />
          <TransactionStatusModal
            isShown={controller.successModal.isModalShown}
            isErrorModal={!controller.successModal.isSuccess}
            onBackClicked={() =>
              controller.setsuccessModal({
                ...controller.successModal,
                isModalShown: false,
              })
            }
          />
          {controller.recieptModal.transactionDetails && (
            <TransactionRecieptModal
              isShown={controller.recieptModal.isModalShown}
              transactionData={controller.recieptModal.transactionDetails}
              closeModal={() =>
                controller.setrecieptModal({
                  ...controller.recieptModal,
                  isModalShown: false,
                })
              }
            />
          )}
          <div className="tb:border max-w-[500px] mx-auto rounded-2xl tb:py-[40px] tb:px-[50px] tb:bg-sprimary-25 border-sprimary-300">
            <p className="text-xl text-sblack-600 font-myriadProSemibold">
              Authorize Transaction
            </p>
            <p className="text-sgrey-2 stext-sm py-2">
              Enter your 4 Digit transaction PIN
            </p>
            <div className="py-5">
              <OtpField
                obsure={true}
                length={controller.otpLenght}
                className="tb:w-[70px] tb:h-[70px] md:w-[70px] md:h-[70px]"
                onChanged={(value) => {
                  // console.log(value, "The val");
                  controller.setotpValue(value);
                }}
              />
            </div>
            {/* <p className="stext-sm py-2 text-sprimary-80 font-myriadProSemibold">
              Resend code in 20
            </p> */}
          </div>
          <div className="pt-5 max-w-[500px] mx-auto md:pt-10">
            <CustomButton
              title="Confirm"
              onClick={controller.confirmOtp}
              isLoading={controller.isLoading}
            />
          </div>
        </div>
      </Scaffold>
    </PageLayout>
  );
};

export default AuthorizeTransaction;
