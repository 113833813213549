import CustomButton from "components/button";
import Card from "components/card";
import CustomForm from "components/form/custom_form";
import CustomInput from "components/form/custom_input";
import CustomDropDown from "components/input/custom_dropdown";
import NavBar from "components/layout/nav_bar";
import PageLayout from "components/layout/page_layout";
import Scaffold from "components/layout/scaffold";
import React, { useEffect } from "react";
import FormVaidation from "utils/form_validation";
import useSendToBank from "./send_to_bank_controller";
import useLoginController from "pages/auth/login_page/login_controller";

const SendToBank = () => {
  const controller = useSendToBank();
  const balance = useLoginController().user.balance;

  useEffect(() => {
    controller.getSupportedCountries();
  }, []);

  const continuePayment = () => {
    controller.formprops.validateForm();
    if (controller.isDisabled()) {
      return;
    }
    controller.continuePayment();
  };

  return (
    <PageLayout>
      <Scaffold appBar={<NavBar title="Send to Bank" canGoBack={true} />}>
        <>
          <div className="sticky top-0 pb-2 bg-white px-4 z-30 space-x-4">
            <Card balance="3,010,830.00" minimized />
          </div>
          <div className=" space-y-4 px-4">
            <CustomForm
              formStateProps={controller.formprops}
              className="pt-2 pb-3 space-y-2"
            >
              <CustomDropDown
                isLoading={controller.isGettingCountries}
                name="country"
                title="Country"
                onItemSelected={(item) => {
                  controller.getBanksFromCountry(item.name);
                  controller.getEquivalentamount(
                    controller.formprops.values["amount"]
                  );
                  controller.getRecipientFromAccountNumber(
                    controller.formprops.values["account-num"]
                  );
                }}
                data={controller.countries.map((country) => {
                  return {
                    name: country.name,
                    logo: country.flag,
                  };
                })}
                placeholder="select a country"
                validator={(value) => {
                  if (value.length < 1) {
                    return `select a country to continue`;
                  }
                  return null;
                }}
              />
              <CustomDropDown
                isLoading={controller.isGettingBanks}
                placeholder="select a bank"
                name="bank"
                title="Bank"
                onItemSelected={() => {
                  controller.getRecipientFromAccountNumber(
                    controller.formprops.values["account-num"]
                  );
                }}
                data={Object.values(controller.banks).map((bank) => {
                  return { name: bank.name };
                })}
                validator={(value) => {
                  if (value.length < 1) {
                    return `select a bank to continue`;
                  }
                  return null;
                }}
              />

              <CustomInput
                name="account-num"
                title="Account number"
                type="number"
                validator={FormVaidation.validateAccountNumber}
                onChanged={(e) => {
                  if (e.target.value.length === 10) {
                    controller.getRecipientFromAccountNumber(e.target.value);
                  }
                }}
              />

              <p className="flex justify-start text-sm text-[#667085] font-myriadProRegular">
                {controller.isGettingRecipient
                  ? "...."
                  : controller.recipient.name}
              </p>

              <CustomInput
                name="amount"
                title="Amount"
                validator={(value) => {
                  if (balance < parseInt(value)) {
                    return "Amount to send is greater than availabe balance";
                  }
                  return FormVaidation.validateAmount(value);
                }}
                type="number"
                onChanged={(e) =>
                  controller.getEquivalentamount(e.target.value)
                }
              />
              <p className="flex justify-start text-sm text-[#667085] font-myriadProRegular">
                {controller.isGettingEquivalentAmount
                  ? "...."
                  : controller.equivalentAmount.text}
              </p>
              <CustomInput
                name="note"
                title="Note"
                inputType="textarea"
                // @ts-ignore
                rows={2}
                // validator={FormVaidation.va}
              />
            </CustomForm>
            <div className="pb-3 max-w-[650px] mx-auto">
              <CustomButton
                title="Continue"
                disabled={controller.isDisabled()}
                onClick={continuePayment}
              />
            </div>
          </div>
        </>
      </Scaffold>
    </PageLayout>
  );
};

export default SendToBank;
