//  { type: string, data: {recipient: string   bank: string    accountNumber: string   amount: string   equivalentAmount: string    rate: string    description: string    fee: "0"  }}

class TransactionModel {
  /**
   * @param {object} props
   * @param {string} props.id
   * @param {string} props.recipient
   * @param {string} props.bank
   * @param {string} props.accountNumber
   * @param {string} props.equivalentAmount
   * @param {string} props.rate
   * @param {string} props.description
   * @param {string} props.fee
   * @param {string} props.status //TransactionStatus
   * @param {string} props.reference
   * @param {string} props.amount
   * @param {Date} props.date
   * @param {string} props.type //TransactionType
   * @param {string} props.state *TransactionState
   */

  constructor({
    name,
    amount,
    date,
    state,
    type,
    accountNumber,
    bank,
    description,
    equivalentAmount,
    fee,
    id,
    rate,
    recipient,
    reference,
    status,
  }) {
    this.name = name;
    this.amount = amount;
    this.date = date;
    this.state = state;
    this.accountNumber = accountNumber;
    this.bank = bank;
    this.description = description;
    this.equivalentAmount = equivalentAmount;
    this.fee = fee;
    this.id = id;
    this.rate = rate;
    this.recipient = recipient;
    this.reference = reference;
    this.status = status;
    this.type = type;
  }

  static fromJson(json) {
    return new TransactionModel({
      name: json["name"],
      amount: json["amount"],
      date: json["date"],
      state: json["state"],
      type: json["type"],
      accountNumber: json["accountNumber"],
      bank: json["bank"],
      description: json["description"],
      equivalentAmount: json["equivalentAmount"],
      fee: json["fee"],
      id: json["id"],
      rate: json["rate"],
      recipient: json["recipient"],
      reference: json["reference"],
      status: json["status"],
    });
  }

  toJson() {
    return {
      name: this.name,
      amount: this.amount,
      date: this.date.getTime(),
      state: this.state,
      type: this.type,
      accountNumber: this.accountNumber,
      bank: this.bank,
      description: this.description,
      equivalentAmount: this.equivalentAmount,
      fee: this.fee,
      id: this.id,
      rate: this.rate,
      recipient: this.recipient,
      reference: this.reference,
      status: this.status,
    };
  }
}

export default TransactionModel;
