const LoadingScreen = () => {
  return (
    <div className="w-screen h-screen flex-center  z-[999] flex-center fixed top-0 left-0">
      <div className="absolute bg-black opacity-50 w-full h-full"></div>
      <div className="w-10 h-10 rounded-full border-2 border-r-transparent border-sprimary animate-spin bg-transparent"></div>
    </div>
  );
};

export default LoadingScreen;
