import React from "react";
import CustomAppBar from "components/layout/app_bar";
import CustomButton from "components/button";
import CustomForm from "components/form/custom_form";
import CustomInput from "components/form/custom_input";
import FormVaidation from "utils/form_validation";
import useLoginController from "./login_controller";
import AuthContainer from "../components/auth_container";
import AuthCard from "../components/auth_card";
import useAppNavigator from "utils/app_navigation";
import AppRoutes from "router/route_paths";
import PageLayout from "components/layout/page_layout";

const LoginPage = () => {
  const controller = useLoginController();
  const navigator = useAppNavigator();
  return (
    <PageLayout animationType="slideIn">
      {controller.toaster}
      <AuthContainer>
        <CustomAppBar />
        <div className="absolute h-full flex flex-col items-center justify-center w-full">
          <AuthCard>
            <div className="flex flex-col py-3 items-start h-full">
              <div className="w-full my-4 px-5 h-full overflow-y-auto">
                <h2 className="stext-heading text-bg">Welcome!</h2>
                <p className="stext-base pb-5">Login to your account</p>

                <CustomForm
                  formStateProps={controller.formStateProps}
                  className="space-y-3 pb-8"
                >
                  <CustomInput
                    name="email"
                    title="Email"
                    className=""
                    validator={FormVaidation.validateEmail}
                  />
                  <CustomInput
                    name="password"
                    title="Password"
                    className=""
                    validator={FormVaidation.validatePassword}
                  />
                </CustomForm>
                <p className="text-sSm text-sprimary py-2 pb-7">
                  Forgot password?
                </p>

                <CustomButton
                  title="Continue"
                  disabled={!controller.isFormValid}
                  onClick={controller.handleFormSubmit}
                  isLoading={controller.isLoading}
                />
                <div
                  className={` flex justify-center w-full pt-5 space-x-2 text-[12px] text-sgrey-2 `}
                >
                  <p>Don’t have an account?</p>
                  <button
                    className="text-sprimary font-bold"
                    onClick={() => navigator.to(AppRoutes.signUp)}
                  >
                    Signup
                  </button>
                </div>
              </div>
            </div>
          </AuthCard>
        </div>
      </AuthContainer>
    </PageLayout>
  );
};

export default LoginPage;
