import useMediaQuery from "hooks/useMediaQuery";
import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import connect_decor from "assets/decor/connect_decor.svg";
import ad_decor_2 from "assets/decor/ad_decor_2.svg";
import refer from "assets/decor/refer.svg";
import CustomButton from "components/button";
import { IconArrowForward } from "utils/app_icons";
import CurrencyExchange from "pages/main_activity/home/sections/currency_exchange";
import useMainActitvity from "pages/main_activity/main_activity_controller";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import useIsKeyboardShown from "hooks/useIsKeyboardShown";

/**
 * A Components that provides layout for including other features
 * @param {object} props
 * @param {JSX.Element=} props.children
 * @param {JSX.Element=} props.bottomNavBar
 * @param {JSX.Element=} props.appBar
 * @param {string=} props.className
 * @param {boolean=} props.inset=fasle
 * @param {boolean=} props.showAd=fasle
 */
const Scaffold = ({
  children,
  bottomNavBar,
  appBar,
  className,
  inset = false,
  showAd = false,
}) => {
  // const appBarRezz
  // console.log(components);
  const size = useMediaQuery();
  const { isKeyboardOpen } = useIsKeyboardShown();
  const isLargeScreen = size.width >= 1024;
  return (
    // <div className={twMerge(`${inset ? "h-full" : "h-screen"} ${className}`)}>
    //   <div ref={appBarRef}>{appBar}</div>
    //   <div
    //     className="pb-3 overflow-y-auto"
    //     style={{
    //       height: `calc(100% - ${components.appBarHeight}px - ${components.bottomNavHeight}px)`,
    //     }}
    //   >
    //     {children}
    //   </div>
    //   <div ref={bottomNavRef}>{bottomNavBar}</div>
    // </div>
    <>
      {!isLargeScreen ? (
        <div
          className={twMerge(
            `flex flex-col ${inset ? "h-full" : "h-[100dvh]"} ${className}`
          )}
        >
          <div className="flex-shrink-0">{appBar}</div>
          <div className="flex-grow overflow-y-auto w-full md:px-4">
            {children}
          </div>
          {!isKeyboardOpen && (
            <div className="flex-shrink-0">{bottomNavBar}</div>
          )}
        </div>
      ) : (
        <div
          className={`flex h-full overflow-clip ${
            inset ? "h-full" : "h-screen"
          }`}
        >
          {bottomNavBar ? (
            <div className="w-[15%] h-full overflow-auto border border-sprimary-300 shadow-[0px_-6px_48px_-12px_rgba(16,24,40,0.3)] pt-7">
              {bottomNavBar}
            </div>
          ) : (
            <></>
          )}
          <div className="flex-grow px-3">
            <div className=" h-full flex flex-col">
              <div className="flex-shrink-0">{appBar}</div>
              <div className="flex-grow overflow-y-auto w-full no-scrollbar pb-2">
                {children}
              </div>
            </div>
          </div>
          {showAd && (
            <div className="w-[23%] h-full overflow-auto py-7 border border-sprimary-300 shadow-[0px_-6px_48px_-12px_rgba(16,24,40,0.3)] no-scrollbar">
              {<AdsSection />}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Scaffold;

const AdsSection = () => {
  const mainController = useMainActitvity();
  const isMain = useLocation().pathname === "/";
  return (
    <div className="px-4 space-y-7">
      <AnimatePresence>
        {mainController.currentIndex !== 0 && (
          <motion.div
            initial={
              isMain && {
                scale: 0,
              }
            }
            animate={isMain && { scale: 1 }}
            exit={{ opacity: 0 }}
          >
            <CurrencyExchange />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="bg-[#F6F4FF] rounded-2xl overflow-clip pb-7 shadow-[0px_4px_20px_-10px_rgba(0,0,0,0.3)]">
        <img src={connect_decor} alt="decor" />
        <div className="px-5 flex justify-center flex-col items-center pt-4 ">
          <h2 className="font-myriadProSemibold stext-base text-sblack-600 text-center">
            Network on Skitpay
          </h2>
          <p className="text-xs text-sgrey-2 text-center pb-5">
            Connect with contacts on Skitpay
          </p>
          <CustomButton
            title={
              <div className="flex items-center">
                Connect{" "}
                <div className="pl-3">
                  <IconArrowForward />
                </div>
              </div>
            }
            className="px-4"
          />
        </div>
      </div>

      <div className="add-card-bg rounded-2xl px-3 relative space-x- shadow-[3px_6px_12px_rgba(101,14,187,0.25)]">
        <img
          src={ad_decor_2}
          alt="ad-decor"
          className="absolute h-full right-0 w-[65%]"
        />
        <div className="grid grid-cols-[minmax(0,1fr)_minmax(0,0.9fr)] relative z-[1]">
          <div className="py-5 text-white space-y-3">
            <h2 className="stext-base text-white font-myriadProSemibold">
              Refer a friend and earn{" "}
            </h2>
            <p className="text-[10px]">
              Get compensated for your referral with either your username or
              referral link
            </p>
          </div>
          <img src={refer} alt="decor" />
        </div>
      </div>
    </div>
  );
};
