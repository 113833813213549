import CustomButton from "components/button";
import CustomForm from "components/form/custom_form";
import useFormState from "components/form/form_state";
import CustomInput from "components/form/custom_input";
import FormVaidation from "utils/form_validation";
import useAppNavigator from "utils/app_navigation";
import useSignUpController from "../signup_controller";
import AppRoutes from "router/route_paths";

const SignUpNames = () => {
  const formStateProps = useFormState();
  const { validateForm, isFormValid } = formStateProps;
  const controller = useSignUpController();
  const navigate = useAppNavigator();

  const handleFormSubmit = () => {
    if (validateForm()) {
      navigate.to(`${AppRoutes.signUp}/2`);
    } else {
      console.log("Not validated");
    }
  };

  return (
    <div className="slide_in_rtl w-full my-4 px-5 h-full overflow-y-auto">
      <h2 className="stext-heading text-bg">Hi!</h2>
      <p className="stext-base pb-5">Create a new individual account</p>

      <CustomForm formStateProps={formStateProps} className="space-y-3 pb-8">
        <CustomInput
          name="firstName"
          title="First Name"
          value={controller.state.userData.firstName}
          onChanged={controller.addstate}
          // validator={FormVaidation.validateSingleName}
        />
        <CustomInput
          name="lastName"
          title="Last Name"
          value={controller.state.userData.lastName}
          onChanged={controller.addstate}
          // validator={FormVaidation.validateSingleName}
        />
      </CustomForm>

      <CustomButton
        title="Continue"
        disabled={!isFormValid}
        onClick={handleFormSubmit}
      />
    </div>
  );
};

export default SignUpNames;
