import flag_us from "components/currency_converter/data/png100px/us.png";
import flag_ng from "components/currency_converter/data/png100px/ng.png";
import flag_in from "components/currency_converter/data/png100px/in.png";
import flag_sg from "components/currency_converter/data/png100px/sg.png";
import flag_bs from "components/currency_converter/data/png100px/bs.png";
import flag_cn from "components/currency_converter/data/png100px/cn.png";
import fabari from 'assets/img/fabari.jpeg'

export const countryMap = {
  india: "India",
  uae: "UAE",
  nigeria: "Nigeria",
  bahamas: "Bahamas",
  china: "China",
  singapre: "Singapore",
  usa: "United States",
};

/**
 * @type {Array<{email:string, password:string, id:number, firstName: string, lastName:string, balance: number, userName: string, country: string, image: string}>}
 */

export const dummyUsers = [
  {
    email: "nehajain@skitpay.com",
    password: "skituser1!Skit",
    id: 1,
    firstName: "Neha",
    lastName: "Jain",
    balance: 20000,
    userName: "nehajain",
    country: countryMap.india,
    image: "",
  },
  {
    email: "hassan@skitpay.com",
    password: "skituser2!Skit",
    id: 2,
    firstName: "Hassan",
    lastName: "Tariq",
    balance: 50000,
    userName: "hassantariq",
    country: countryMap.uae,
    image: "",
  },
  {
    email: "fabari@skitpay.com",
    password: "skituser2!Skit",
    id: 3,
    firstName: "Fabari",
    lastName: "Tariq",
    balance: 50000,
    userName: "fabari",
    country: countryMap.nigeria,
    image: fabari,
  },
];

export const dummysupportedCountries = [
  {
    name: countryMap.uae,
    shortCode: "AED",
    flag: flag_bs,

    currency: {
      symbol: "د.إ",
      name: "Dirham",
    },
  },
  // {
  //   name: countryMap.bahamas,
  //   shortCode: "BSD",
  //   flag: flag_bs,

  //   currency: {
  //     symbol: "$",
  //     name: "Bahamian Dollar",
  //   },
  // },

  // {
  //   name: countryMap.china,
  //   shortCode: "CNY",
  //   flag: flag_cn,
  //   currency: {
  //     symbol: "¥",
  //     name: "Yuan",
  //   },
  // },

  {
    name: countryMap.india,
    shortCode: "INR",
    flag: flag_in,
    currency: {
      symbol: "₹",
      name: "Rupee",
    },
  },
  {
    name: countryMap.nigeria,
    shortCode: "NGN",
    flag: flag_ng,
    currency: {
      symbol: "₦",
      name: "Naira",
    },
  },
  // {
  //   name: countryMap.singapre,
  //   shortCode: "SGD",
  //   flag: flag_sg,
  //   currency: {
  //     symbol: "S$",
  //     name: " Singapore dollar",
  //   },
  // },
  // {
  //   name: countryMap.usa,
  //   shortCode: "USD",
  //   flag: flag_us,
  //   currency: {
  //     symbol: "$",
  //     name: "Dollar",
  //   },
  // },
];

export const dummyBanks = {
  [countryMap.nigeria]: {
    "Access Bank": {
      name: "Access Bank",
      id: 1,
      userAccounts: [
        {
          id: 1,
          name: "Peter Jury",
          accountNumber: "2087319301",
        },
        {
          id: 1,
          name: "Achego Tamunobelema",
          accountNumber: "0775925922",
        },
      ],
    },
  },
  [countryMap.uae]: {
    "Capital Union Bank": {
      name: "Capital Union Bank",
      id: 1,
      userAccounts: [
        {
          id: 1,
          name: "James Peter",
          accountNumber: "2087319301",
        },
        {
          id: 2,
          name: "Godson Chucks",
          accountNumber: "2087319302",
        },
      ],
    },
  },
  [countryMap.india]: {
    "Canara Bank": {
      name: "Canara Bank",
      id: 1,
      userAccounts: [
        {
          id: 1,
          name: "Ritesh Flavour",
          accountNumber: "2087319301",
        },
        {
          id: 2,
          name: "Prashi Faith",
          accountNumber: "2087319302",
        },
      ],
    },
    "Bank Of Baroda": {
      name: "Bank Of Baroda",
      id: 1,
      userAccounts: [
        {
          id: 1,
          name: "Ritesh Flavour",
          accountNumber: "2087319301",
        },
        {
          id: 2,
          name: "Prashi Faith",
          accountNumber: "2087319302",
        },
      ],
    },
  },
};

/**
 * @type {Array<{countries: Array<string>, value: number}>}
 */
export const dummyConversions = [
  {
    countries: ["NGN", "AED"],
    value: 0.007973856208,
  },
  {
    countries: ["NGN", "INR"],
    value: 0.1782307111,
  },
  {
    countries: ["AED", "INR"],
    value: 22.31309383,
  },
];

export const curencyShortcodetoSymbol = {
  USD: "$", // US Dollar
  BSD: "$",
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
  AED: "د.إ", // Vietnamese Dong
};

export const dummyUserPin = 3467;
