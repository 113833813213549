import React, { useEffect, useRef, useState } from "react";

const useIsKeyboardShown = () => {
  const [isKeyboardOpen, setisKeyboardOpen] = useState(false);
  const inner = useRef(window.outerHeight).current;
  const [keyBoardHeight, setkeyBoardHeight] = useState(0);
  const listner = () => {
    setkeyBoardHeight(inner - window.visualViewport.height);
    if (window.visualViewport.height < inner) {
      setisKeyboardOpen(true);
      return;
    }
    setisKeyboardOpen(false);
  };
  useEffect(() => {
    window.visualViewport.addEventListener("resize", listner);

    return () => {
      window.visualViewport.removeEventListener("resize", listner);
    };
  }, []);

  return { isKeyboardOpen, keyBoardHeight };
};

export default useIsKeyboardShown;
