
class BottomNavBarModel {
    /**
     * @param {object} param
     * @param {JSX.Element} param.icon
     * @param {string} param.title
     * @param {string} [param.routePath]
     */
    constructor({icon, title, routePath}){
        this.icon = icon;
        this.title = title;
        this.routePath = routePath;
    }
}

export default BottomNavBarModel;
