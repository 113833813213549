import { useContext } from "react";
import NotificationProvider, {
  NotificationContext,
} from "./app_notification_context";

const useAppNotifications = () => {
  const { notifications, setnotifications } = useContext(NotificationContext);

  /**
   *
   * @param {string} id
   * @param {object} props
   * @param {any} props.component
   */
  const showBottomModal = (id, { component }) => {
    const newNotif = [...notifications];
    newNotif.push({
      type: "bottom_modal",
      id,
      component: <NotificationProvider>{component}</NotificationProvider>,
    });
    setnotifications(newNotif);
  };
  /**
   *
   * @param {string} id
   * @param {object} props
   * @param {any} props.component
   */
  const showDialogue = (id, { component }) => {
    // const newNotif = [...mainState.notifications];
    const newNotif = [...notifications];
    newNotif.push({
      type: "dialogue",
      id,
      component,
    });
    setnotifications(newNotif);
    // setState({state: 'notifications', value: newNotif})
  };

  const incr = () => {
    // setState({state: 'count', value: mainState.count + 1})
  };
  const closeModal = () => {
    const newNotif = [...notifications];
    newNotif.pop();

    console.log(newNotif, " New Notif");

    setnotifications(newNotif);
  };

  return {
    showBottomModal,
    closeModal,
    notifications,
    showDialogue,
    incr,
  };
};

export default useAppNotifications;
