// import setGlobalState from "components/useGlobalStore";
import { Reducers } from "global_store/reducers";
import setGlobalState, { useActions } from "global_store/useGlobalStore";
import ApiProviderAuth from "providers/api_provider_auth";
import AppRoutes from "router/route_paths";
import useAppNavigator from "utils/app_navigation";

// // Setting up Global State
/**
 * @typedef {object} State
 * @property {object} userData
 * @property {bool} isLoading
 *
 */

/**
 * @type {State}
 */
const initialState = {
  userData: {},
  isLoading: false,
};
const name = "signupController";
const { controllerAction, controllerReducer } = setGlobalState({
  name,
  initialState,
});

// After adding this line and exporting, add the signup reducer to the store
export const signupReducer = controllerReducer;

// Controller Hook
const useSignUpController = () => {
  const navigate = useAppNavigator();

  const { state, setState } = useActions({
    controllerAction,
    reducer: Reducers.signUp,
  });
  /**
   * @type {State}
   */
  const mainState = state;

  const addstate = (e) => {
    setState({
      state: "userData",
      value: { ...state.userData, [e.target.name]: e.target.value },
    });
    console.log(state.userData);
  };

  const signUpUser = async () => {
    setState({ state: "isLoading", value: true });
    const response = await ApiProviderAuth.signUp({ ...mainState.userData });
    console.log(response);
    if (!response.isOk) {
      setState({ state: "isLoading", value: false });

      return;
    }
    navigate.offAllAndto(AppRoutes.logIn);
    console.log(response);
    setState({
      state: "userData",
      value: {},
    });
    setState({ state: "isLoading", value: false });
    // navigate.to(AppRoutes.otp);
  };

  return {
    signUpUser,
    addstate,
    state: mainState,
    setState,
  };
};

// export {signupReducer}
export default useSignUpController;
