import InactivityTimer from "components/global/inactivity_timer";
import ProtectedRoute from "components/navigators/protected_routes";
import { AnimatePresence } from "framer-motion";
import useMediaQuery from "hooks/useMediaQuery";
import Account from "pages/account/account";
// import { CreditDebit, DepositMoney } from "pages/app";
import SignUpEssentialDetails from "pages/auth/signup_page/components/signup_essential_details";
import SignUpNames from "pages/auth/signup_page/components/signup_names";
import AuthorizeTransaction from "pages/authorize_transaction/authorize_transaction";
import BankTransfer from "pages/bank_transfer/bank_transfer";
import ConfirmTransaction from "pages/confirm_transaction/confirm_transaction";
import CreditDebitCard from "pages/credit_debit_card/credit_debit_card";
import DepositMoney from "pages/deposit_money/deposit_money";
import EditProfile from "pages/edit_profile/edit_profile";
import Home from "pages/main_activity/home/home";
import MainActivity from "pages/main_activity/main_activity";
import Payment from "pages/main_activity/payment/payment";
import SendMoney from "pages/send_money/send_money";
import SendToBank from "pages/send_to_bank/send_to_bank";
import SendToSkitpayUser from "pages/send_to_skitpay_user/send_to_skitpay_user";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes,
  useLocation,
} from "react-router-dom";
import LoginPage from "../pages/auth/login_page/login_page";
import OtpPage from "../pages/auth/otp_page/otp_page";
import SignUpPage from "../pages/auth/signup_page/signup_page";
import WelcomePage from "../pages/auth/welcome_page";
import AppRoutes from "./route_paths";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path={AppRoutes.welcome} element={<WelcomePage />} />,
      <Route path={AppRoutes.signUp} element={<SignUpPage />} />,
      <Route path={AppRoutes.logIn} element={<LoginPage />} />,
      <Route path={AppRoutes.otp} element={<OtpPage />} />
      <Route path={AppRoutes.mainActivity} element={<MainActivity />}>
        <Route path={AppRoutes.home} element={<Home />} />
      </Route>
    </Route>
  )
);

const AppRouter = () => {
  const location = useLocation();
  const isLargeScreen = useMediaQuery().width >= 1024;
  return (
    <AnimatePresence>
      {/* <InactivityTimer key={location.pathname}> */}
      {isLargeScreen ? (
        <Routes location={location} key={location.pathname}>
          <Route path={AppRoutes.welcome} element={<WelcomePage />} />,
          <Route path={AppRoutes.signUp} element={<SignUpPage />}>
            <Route index element={<SignUpNames />} />
            <Route path="2" element={<SignUpEssentialDetails />} />
          </Route>
          <Route path={AppRoutes.logIn} element={<LoginPage />} />,
          <Route path={AppRoutes.otp} element={<OtpPage />} />
          <Route
            path={AppRoutes.mainActivity}
            element={<ProtectedRoute element={<MainActivity />} />}
          >
            <Route
              path={AppRoutes.sendMoney}
              element={<ProtectedRoute element={<SendMoney />} />}
            />
            <Route
              path={AppRoutes.sendToBank}
              element={<ProtectedRoute element={<SendToBank />} />}
            />
            <Route
              path={AppRoutes.sendToSkitppayUser}
              element={<SendToSkitpayUser />}
            />
            <Route
              path={AppRoutes.confirmTransaction}
              element={<ConfirmTransaction />}
            />
            <Route
              path={AppRoutes.authorizetransaction}
              element={<AuthorizeTransaction />}
            />
            <Route path={AppRoutes.depositMoney} element={<DepositMoney />} />
            {/* <Route path={AppRoutes.creditDebit} element={<CreditDebit />} /> */}
            <Route path={AppRoutes.creditDebit} element={<CreditDebitCard />} />
            <Route path={AppRoutes.bankTransfer} element={<BankTransfer />} />
            <Route path={AppRoutes.account} element={<Account />} />
            <Route path={AppRoutes.editProfile} element={<EditProfile />} />
          </Route>
        </Routes>
      ) : (
        <Routes location={location} key={location.key}>
          <Route path={AppRoutes.welcome} element={<WelcomePage />} />,
          <Route path={AppRoutes.signUp} element={<SignUpPage />}>
            <Route index element={<SignUpNames />} />
            <Route path="2" element={<SignUpEssentialDetails />} />
          </Route>
          ,
          <Route path={AppRoutes.logIn} element={<LoginPage />} />,
          <Route path={AppRoutes.otp} element={<OtpPage />} />
          <Route
            path={AppRoutes.mainActivity}
            element={<ProtectedRoute element={<MainActivity />} />}
          />
          <Route path={AppRoutes.sendMoney} element={<SendMoney />} />
          <Route path={AppRoutes.sendToBank} element={<SendToBank />} />
          <Route
            path={AppRoutes.sendToSkitppayUser}
            element={<SendToSkitpayUser />}
          />
          <Route
            path={AppRoutes.confirmTransaction}
            element={<ConfirmTransaction />}
          />
          <Route
            path={AppRoutes.authorizetransaction}
            element={<AuthorizeTransaction />}
          />
          <Route path={AppRoutes.depositMoney} element={<DepositMoney />} />
          {/* <Route path={AppRoutes.creditDebit} element={<CreditDebit />} /> */}
          <Route path={AppRoutes.creditDebit} element={<CreditDebitCard />} />
          <Route path={AppRoutes.bankTransfer} element={<BankTransfer />} />
          <Route path={AppRoutes.account} element={<Account />} />
          <Route path={AppRoutes.editProfile} element={<EditProfile />} />
        </Routes>
      )}
      {/* </InactivityTimer> */}
    </AnimatePresence>
  );
};

export default AppRouter;
