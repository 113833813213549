import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import CustomButton from "components/button";
import Lottie from "react-lottie";
import success from "assets/lottie/send-money-success.json";
import failed from "assets/lottie/send-money-failed.json";
import useAppNavigator from "utils/app_navigation";
import AppRoutes from "router/route_paths";
import useMainActitvity from "pages/main_activity/main_activity_controller";

/**
 *
 * @param {object} props
 * @param {boolean=} props.isErrorModal
 * @param {boolean=} props.isShown
 * @param {()=>void=} props.onBackClicked
 */
const TransactionStatusModal = ({
  isErrorModal = false,
  isShown = false,
  onBackClicked,
}) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: isErrorModal ? failed : success,
  };
  const navigate = useAppNavigator();
  const mController = useMainActitvity();
  return (
    <AnimatePresence>
      {isShown && (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ opacity: 0 }}
          className="w-full h-full absolute bg-white top-0 left-0 px-4 py-6 flex flex-col z-30 items-center"
        >
          <div className="h-[50%] w-full rounded-md grid place-items-center py-12">
            {!isErrorModal ? (
              <Lottie options={defaultOptions} />
            ) : (
              <Lottie options={defaultOptions} />
            )}
          </div>
          <div className="px-5 flex flex-col items-center">
            <h2 className="pt-8 pb-2 text-2xl text-center">
              {!isErrorModal ? "Sent!" : "Could not send, check your pin"}
            </h2>
            <p className="text-center stext-sm">
              {!isErrorModal
                ? "Money has been sent to the receipient. You’ll be notified on when receipient’s gets it"
                : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, ut molestie praesent sit pretium eget at accumsan."}
            </p>
          </div>

          <div className="flex-grow flex items-end justify-center w-full">
            <CustomButton
              title={isErrorModal ? "Back" : "Ok"}
              className="max-w-[300px] w-full"
              onClick={
                isErrorModal
                  ? onBackClicked
                  : () => {
                      navigate.offAllAndto(AppRoutes.mainActivity);
                      mController.setcurrentIndex(0);
                    }
              }
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default TransactionStatusModal;
