// @ts-check

import axios, { AxiosError } from "axios";
import CustomResponse from "models/custom_response";
import Strings from "utils/strings";

export const baseInstance = axios.create({
  baseURL: Strings.API_BASE_URL,
  // timeout: 6000,
  headers: { "Content-Type": "application/json" },
});

class ApiProviderAuth {
  static signUp = async (body) => {
    console.log(body);
    try {
      const response = await baseInstance.post("api/v1/users/signup", body);
      console.log("Gotten resp");
      console.log(response.data);
      console.log(response.status);
      return new CustomResponse({
        data: response.data,
        submessage: "Successfully Signed up user",
        statusCode: response.status,
      });
    } catch (err) {
      console.log(err);
      return new CustomResponse({
        message: "An Unknown Error ocuured",
        submessage: err.response.data.message ?? "",
      });
    }
  };

  static login = async (body) => {
    console.log(body);

    try {
      const response = await baseInstance.post("api/v1/users/login", body);
      console.log("Gotten resp");
      console.log(response.data);
      console.log(response.status);
      return new CustomResponse({
        data: response.data,
        submessage: "Login Successfull",
        statusCode: response.status,
      });
    } catch (err) {
      console.log(err);
      return new CustomResponse({
        message: "Error",
        submessage: err.message ?? "",
      });
    }
  };
}

export default ApiProviderAuth;
