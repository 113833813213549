// @ts-check
import React from "react";
import { twMerge } from "tailwind-merge";

/**
 *
 * @param {object} props
 * @param {string} props.title
 * @param {string=} props.className
 * @param {() => any=} props.onClick
 * @param {boolean=} props.disabled
 * @param {boolean=} props.filled
 * @param {boolean=} props.isLoading
 * @returns {JSX.Element}
 */
const CustomButton = ({
  title,
  onClick,
  className,
  disabled = false,
  filled = true,
  isLoading = false,
}) => {
  const basicStlye = filled
    ? `${
        !disabled
          ? "btn-bg text-white border hover:bg-transparent hover:text-white  hover:border-sprimary"
          : "bg-disabled text-sgrey-3"
      } `
    : "text-sprimary hover:bg-sprimary hover:text-white border border-sprimary";
  return (
    <button
      className={twMerge(` 
     rounded-[10px] h-[50px] w-full flex justify-center items-center text-[12.4px] font-semibold transition-all shadow-lg  duration-300 active:scale-95  ${basicStlye} ${className}`)}
      onClick={isLoading  ? null : onClick}
    >
      {isLoading ? (
        <div className="animate-spin w-[25px] h-[25px] rounded-full border-[3px] border-[#d4d4d4c9] border-r-[4px] border-r-white shadow-[2px_0px_9px_rgb(255,255,255,0.3)]"></div>
      ) : (
        title
      )}
    </button>
  );
};

export default CustomButton;
