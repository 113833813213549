// @ts-check
import NavBar from "components/layout/nav_bar";
import Scaffold from "components/layout/scaffold";
import { TransctionType } from "components/transaction_item";
import TransactionModel from "models/transaction_model";
import React, { useState } from "react";
import { IconStats } from "utils/app_icons";
import RecentTransactions from "../home/sections/recent_transactions";

import HistoryCard from "./sections/history_card";
import useHistoryController from "./history_controller";


const History = () => {
  const controller = useHistoryController()
  const transactions = controller.state.transactions.map(trasnsaction => {
    return TransactionModel.fromJson(trasnsaction)
  })
  return (
    <Scaffold appBar={<NavBar title="History" />} inset>
      <div className="px-4">
        <div className="sticky top-0">
        <HistoryCard />
        </div>
        <div className="py-3">
          <RecentTransactions transactions={transactions} />
        </div>
      </div>
    </Scaffold>
  );
};

export default History;



