// @ts-check

import Card from "components/card";
import NavBar from "components/layout/nav_bar";
import React from "react";
import { IconNotification } from "utils/app_icons";
import CurrencyExchange from "./sections/currency_exchange";
import RecentTransactions from "./sections/recent_transactions";
import p_image from "assets/p_image.png";
import Scaffold from "components/layout/scaffold";
import useAppNavigator from "utils/app_navigation";
import AppRoutes from "router/route_paths";
import TransactionModel from "models/transaction_model";
import { TransctionType } from "components/transaction_item";
import useHomeController from "./home_controller";
import useLoginController from "pages/auth/login_page/login_controller";

const Home = () => {
  const navigate = useAppNavigator();
  const controller = useHomeController();
  const user = useLoginController().user;

  return (
    <Scaffold
      appBar={
        <NavBar
          leading={
            <div className="h-[24px] w-[24px] rounded-full bg-slate-200 overflow-clip">
              <img src={user.image?user.image:p_image} alt="profile" className="w-full h-auto" />
            </div>
          }
          actions={[
            <div key="notif" 
            // onClick={() => navigate.to(AppRoutes.welcome)}
            >
              <IconNotification key={1} />
            </div>,
          ]}
          title={
            <>
              <span className="font-myriadProRegular">Hi, </span>
              {user.firstName}
            </>
          }
        />
      }
      inset
    >
      <div className="px-4">
        <div className="sticky top-0 z-[1] pb-3 bg-white">
          <Card balance={user.balance} />
        </div>
        <div className="pb-3">
          <CurrencyExchange />
          <RecentTransactions
            transactions={controller.transactions}
            showReciept={false}
            isLoading={controller.isLoading}
          />
        </div>
      </div>
    </Scaffold>
  );
};

export default Home;
