import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { cardReducer } from "components/card";
import { signupReducer } from "pages/auth/signup_page/signup_controller";
import { mainReducer } from "pages/main_activity/main_activity_controller";
import { Reducers } from "./reducers";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import { loginReducer } from "pages/auth/login_page/login_controller";
import { historyReducer } from "pages/main_activity/history/history_controller";

const persistConfig = {
  key: "root",
  storage,
  //   stateReconciler: autoMergeLevel2, // allow merger 2 levels deep
  whitelist: [Reducers.login, Reducers.transactionHistory], // only reduces i this list are allowed to persist
};

const root = combineReducers({
  [Reducers.main]: mainReducer,
  [Reducers.card]: cardReducer,
  [Reducers.signUp]: signupReducer,
  [Reducers.login]: loginReducer,
  [Reducers.transactionHistory]: historyReducer,
});
const persistedReducer = persistReducer(persistConfig, root);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
});

export default store;
export const persistedStore = persistStore(store);
