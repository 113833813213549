// @ts-check
import NavBar from "components/layout/nav_bar";
import Scaffold from "components/layout/scaffold";
import React, { useState } from "react";
import p_image from "assets/p_image.png";
import PaymentItem from "../payment/sections/payment_item";
import { IconArrowForwardIos, IconEdit, IconProfile } from "utils/app_icons";
import CustomInput from "components/form/custom_input";
import useAppNavigator from "utils/app_navigation";
import AppRoutes from "router/route_paths";
import useAppNotifications from "components/global/notifications/use_app_notifications";
import useLoginController from "pages/auth/login_page/login_controller";

const Profile = () => {
  const navigate = useAppNavigator();
  const lController = useLoginController();
  return (
    <Scaffold appBar={<NavBar title="Profile" />} inset>
      <div className="px-4">
        <ProfileCard />
        <div className="py-4 space-y-4">
          <PaymentItem
            icon={<IconProfile className="text-white" />}
            title="Account"
            showIcon={true}
            // onPressed={() => navigate.to(AppRoutes.account)}
          />
          <PaymentItem
            icon={<IconProfile className="text-white" />}
            title="Referrals"
            showIcon={true}
          />
          <PaymentItem
            icon={<IconProfile className="text-white" />}
            title="Support/Help"
            showIcon={true}
          />
          <PaymentItem
            icon={<IconProfile className="text-white" />}
            title="Terms & Conditions"
            showIcon={true}
          />
          {/* <PaymentItem
            icon={<IconProfile className="text-white" />}
            title="Settings"
            showIcon={true}
          /> */}
          <PaymentItem
            icon={<IconProfile className="text-white" />}
            title="Log out"
            showIcon={true}
            onPressed={() => {
              lController.logOut();
            }}
          />
        </div>
      </div>
    </Scaffold>
  );
};

export default Profile;

const ProfileCard = () => {
  const [showModal, setshowModal] = useState(false);
  const hasUserName = false;
  const navigate = useAppNavigator();
  const notification = useAppNotifications();
  const user = useLoginController().user;

  return (
    <div
      className="bg-sprimary-80 text-white rounded-2xl px-6 py-11 flex justify-between items-center"
      onClick={() => navigate.to(AppRoutes.editProfile)}
    >
      {/* <div className="bg-white fixed bottom-0 left-0 w-full flex justify-center items-center flex-col px-5 py-6 shadow-xl rounded-t-[25px] border text-center space-y-3">
        <h2 className="text-sblack-600 text-2xl">Create a Username</h2>
        <p className="text-sblack-600 text-sm">Send and receive money easily with a username. Make sure to crosscheck your input as this cannot be changed</p>
        <CustomInput name="username" title="Username" className="w-full"/>
      </div> */}
      <div className="flex space-x-3 items-center">
        <img
          src={user.image ? user.image : p_image}
          alt="profile_image"
          className="w-14 h-14 rounded-full"
        />
        <div className="space-y-1">
          <h2>{`${user.firstName} ${user.lastName}`}</h2>
          <button
            className="px-3 py-1 rounded-md bg-slilac text-sm font-myriadProSemibold flex space-x-1  items-center"
            // onClick={() =>
            //   notification.showBottomModal("mdmd", {
            //     component: <div className="bg-white h-[200px]">hello</div>,
            //   })
            // }
          >
            <IconEdit />
            <p>{user.userName ? `@${user.userName}` : "create username"}</p>
          </button>
        </div>
      </div>
      <IconArrowForwardIos className="fill-white" />
    </div>
  );
};
