import { type } from "@testing-library/user-event/dist/type";
import TransactionRecieptModal from "components/notifications/transaction_reciept_modal";
import TransactionItem, {
  TransctionStatus,
  TransctionType,
} from "components/transaction_item";
import TransactionModel from "models/transaction_model";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import { formatDate, formatTime } from "utils/functions";

/**
 *
 * @param {object} props
 * @param {boolean=} props.isHistory
 * @param {Array<TransactionModel>} props.transactions
 * @param {boolean=} props.showReciept
 * @param {boolean=} props.onlyToday
 * @param {boolean=} props.isLoading
 */
const RecentTransactions = ({
  showAll = false,
  transactions = [],
  showReciept = true,
  onlyToday = false,
  isLoading = false,
}) => {
  // * @type {object<string, Array<TransactionModel>> }

  /**
   * @typedef {Object} GroupedTrans
   * @property {}
   */
  /**
   * @type {Object<string, Array<TransactionModel>>}
   */
  let groupedTransctions = {};

  transactions.forEach((trans) => {
    console.log(trans.date, "Rhhe dat");
    const date = new Date(trans.date);
    const key = formatDate(date);
    console.log(key, "Date");
    if (!groupedTransctions[key]) {
      groupedTransctions[key] = [];
    }
    groupedTransctions[key].push(trans);
  });

  const [isModalShown, setisModalShown] = useState(false);
  const [transDetails, settransDetails] = useState({});
  // let transDetails = {};

  return (
    <div>
      <TransactionRecieptModal
        isShown={isModalShown}
        transactionData={transDetails}
        closeModal={() => setisModalShown(false)}
        isBarrearDismisable={true}
      />
      <div className="flex pt-5 pb-3 items-center justify-between">
        <p className="text-sm font-semibold text-sgrey-600">
          Recent Transaction
        </p>
        <button className="text-xs font-semibold text-sgrey-600">
          {showAll ? "View All" : ""}
        </button>
      </div>

      {isLoading && transactions.length < 1 ? (
        <RecentTransactionsLoader />
      ) : (
        <>
          {transactions.length <= 0 ? (
            <p className="text-lg text-sgrey-4 font-myriadProSemibold grid place-items-center pt-11">
              No transaction History
            </p>
          ) : (
            <div className="space-y-5">
              {Object.keys(groupedTransctions).map((date, ind) => {
                return (
                  <div key={ind}>
                    <p className="text-xs font-myriadProSemibold">{date}</p>
                    <div className="space-y-3">
                      {groupedTransctions[date].map((trans, ind) => {
                        const time = formatTime(new Date(trans.date));
                        return (
                          <TransactionItem
                            key={ind}
                            amount={trans.amount.replace("₦", "")}
                            name={trans.recipient}
                            transactionType={trans.state}
                            time={time}
                            onClick={
                              !showReciept
                                ? null
                                : () => {
                                    // settransDetails({
                                    //   amount: trans.amount,
                                    //   from: "@BELEMA",
                                    //   to: trans.name.toUpperCase(),
                                    //   type: trans.type.toUpperCase(),
                                    //   date: date,
                                    //   ref: "string",
                                    //   equivalentAmount: "number",
                                    //   status: "Stats",
                                    //   fee: "number",
                                    // });
                                    settransDetails(trans);
                                    setisModalShown(true);
                                  }
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              {/* {transactions.map((trans) => {
            const time = formatTime(trans.date)
            return (
              <TransactionItem
                amount={trans.amount}
                name={trans.name}
                transactionType={TransctionType.debit}
                time={time}
              />
            );
          })} */}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RecentTransactions;

const RecentTransactionsLoader = () => {
  return (
    <div className="space-y-3">
      {[0, 1, 2].map((ind) => (
        <TransactionItem isLoading={true} key={ind} />
      ))}
    </div>
  );
};
