import OtpField from "components/input/otp_field";
import PageLayout from "components/layout/page_layout";
import LoadingScreen from "components/loader/loading_screen";
import PopUpModal from "components/notifications/pop_up_modal";
import AuthCard from "../components/auth_card";
import AuthContainer from "../components/auth_container";
import useOtpController from "./otp_controller";

const OtpPage = () => {
  const controller = useOtpController();
  console.log(controller.modal.subMessage);
  return (
    <AuthContainer>
      <PageLayout animationType="slideIn">
        {/* <CustomAppBar /> */}
        <PopUpModal
          isShown={controller.modal.isShown}
          setIsShown={(value) =>
            controller.setmodal({ ...controller.modal, isShown: value })
          }
          message={controller.modal.message}
          subMessage={controller.modal.subMessage}
        />
        {controller.isLoading ? <LoadingScreen /> : null}
        <div className="absolute h-full flex flex-col items-center justify-center w-full">
          <AuthCard>
            <div className="flex flex-col py-3 items-start h-full">
              <div className="w-full my-4 px-5 h-full overflow-y-auto">
                <h2 className="stext-heading text-bg md:pb-4">Enter OTP</h2>
                <p className="stext-base pb-5">
                  A code was sent to **** ****891
                </p>
                <div className="md:py-5 lg:py-8">
                  <OtpField
                    onChanged={(val) => {
                      controller.setotpValue(val);
                      controller.verifyOtp(val);
                    }}
                    length={controller.otpLength}
                    verifyOtp={(isOtpComplete) =>
                      controller.verifyOtp(isOtpComplete)
                    }
                  />
                </div>
                <div
                  className={` flex justify-center w-full pt-5 space-x-2 text-xs text-sgrey-2  `}
                >
                  <p className="">Didn't get a code?</p>
                  <button className="text-sprimary font-bold">
                    Resend code in 20
                  </button>
                </div>
              </div>
            </div>
          </AuthCard>
        </div>
      </PageLayout>
    </AuthContainer>
  );
};

export default OtpPage;
