export const TransactionType = {
  skitUser: "skit_user",
  bank: "bank",
};

export const TransactionStatus = {
  success: "success",
};
export const TransactionState = {
  debit: "debit",
  credit: "credit",
};
