// @ts-check
import React from "react";
import { twMerge } from "tailwind-merge";
import { IconArrowBottomLeft, IconArrowTopRight } from "utils/app_icons";

export const TransctionType = {
  debit: "debit",
  credit: "credit",
};
export const TransctionStatus = {
  sent: "sent",
  scheduled: "scheduled",
  recieved: "recieved",
};
const TransactionItem = ({
  name,
  time,
  amount,
  className,
  transactionType = TransctionType.debit,
  transactionStatus = TransctionStatus.sent,
  onClick,
  isLoading = false,
}) => {
  const isDebit = transactionType === TransctionType.debit;

  return (
    <>
      {isLoading ? (
        <div className="py-2 px-3 bg-sprimary-300 rounded-lg animate-pulse">
          <div className="flex flex-col items-end">
            <p className="stext-sm opacity-0">₦00</p>
            {buildTransactionBox("Load")}
          </div>
        </div>
      ) : (
        <div
          onClick={onClick}
          className={twMerge(
            `sanimate-fade-in px-3 py-2 rounded-lg flex justify-between items-center ${
              isDebit ? "bg-[#FCECEC]" : "bg-[#00800014]"
            }  ${className}`
          )}
        >
          <div className="flex items-center space-x-4">
            {isDebit ? (
              <div className="border-l border-[#DD8383] pl-1">
                <IconArrowTopRight />
              </div>
            ) : (
              <div className="border-b border-slilac py-1">
                <IconArrowBottomLeft />
              </div>
            )}
            <div className="">
              <p className="stext-sm font-semibold text-sblack-600">{name}</p>
              <p className="text-xs text-sgrey-2">{time}</p>
            </div>
          </div>
          <div className="flex flex-col items-end">
            <p className="stext-sm font-semibold text-sblack-600">
              {isDebit ? "-" : "+"} {amount}
            </p>
            {buildTransactionBox(transactionType)}
          </div>
        </div>
      )}
    </>
  );
};

export default TransactionItem;

const buildTransactionBox = (transType) => {
  switch (transType) {
    case TransctionType.credit:
      return (
        <p className="text-xs text-white bg-[#8567E7] text-center w-fit px-3 py-[0.15rem] rounded-md">
          Received
        </p>
      );
    case TransctionType.debit:
      return (
        <p className="text-xs text-white bg-[rgba(203,31,31,0.5)] text-center w-fit px-3 py-[0.15rem] rounded-md">
          Sent
        </p>
      );

    default:
      return <p className="opacity-0">Loading</p>;
  }
};
