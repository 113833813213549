import useFormState from "components/form/form_state";
import { dummyUser, dummyUsers, dummysupportedCountries } from "data/dummyData";
import { Reducers } from "global_store/reducers";
import setGlobalState, { useActions } from "global_store/useGlobalStore";
import ApiProviderAuth from "providers/api_provider_auth";
import { useState } from "react";
import { Routes } from "react-router-dom";
import AppRoutes from "router/route_paths";
import useAppNavigator from "utils/app_navigation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useHistoryController from "pages/main_activity/history/history_controller";

/**
 * @typedef {object} State
 * @property {string} token
 * @property {{ country: {name: string, shortCode: string} ,currency: string, email:string, password:string, id:number, firstName: string, lastName:string, balance: number, userName: string, image: string}} user
 */

/**
 * @type {State}
 */
const initialState = {
  token: null,
  user: null,
};
const { controllerAction, controllerReducer } = setGlobalState({
  initialState,
  name: "login",
});
export const loginReducer = controllerReducer;

const useLoginController = () => {
  const [isLoading, setisLoading] = useState(false);
  const navigate = useAppNavigator();
  // Stetes
  const formStateProps = useFormState();
  const { validateForm, values, isFormValid } = formStateProps;
  const hController = useHistoryController();

  /**
   * @type {{state: State, setState: ({ state, value }: {state: string;value: State;}) => void}
   */
  const { state, setState } = useActions({
    reducer: Reducers.login,
    controllerAction,
  });

  //   Methods
  const handleFormSubmit = () => {
    if (validateForm()) {
      // console.log("Valdated");
      // console.log(values);
      loginUser();
    } else {
      // console.log("Not validated");
    }
  };

  const loginUser = async () => {
    setisLoading(true);
    // const response = await ApiProviderAuth.login(values);
    // console.log(response);
    // console.log(values);
    // console.log(dummyUser);
    var isUser = false;
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setisLoading(false);
    dummyUsers.forEach(async (user) => {
      // console.log(user, "User");
      if (user.email.trim() === values.email.trim()) {
        if (user.password.trim() !== values.password.trim()) {
          toast.error("Please check your credentials and try again");
          isUser = true;
        } else {
          toast.success("Login was succesfull");
          isUser = true;
          await new Promise((resolve) => setTimeout(resolve, 1000));
          navigate.offAllAndto(AppRoutes.mainActivity);
          setState({ state: "token", value: "token" });
          const country = dummysupportedCountries.find(
            (country) => country.name === user.country
          );
          setState({
            state: "user",
            value: {
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              balance: user.balance,
              country: { name: user.country, shortCode: country.shortCode },
              currency: country.currency.symbol,
              image: user.image,
              userName: user.userName,
            },
          });

          return;
        }
      }
    });

    if (!isUser) {
      toast.warning("No account was found");
      logOut(false);
    }

    // if (!response.isOk) {
    //   setisLoading(false);
    //   return;
    // }
    // setState({ state: "token", value: response.data.token });
    // setisLoading(false);
    // navigate.offAllAndto(AppRoutes.mainActivity);
  };

  const logOut = (shouldNavigate = true) => {
    setState({ state: "token", value: null });
    setState({ state: "user", value: {} });
    if (shouldNavigate) {
      navigate.offAllAndto(AppRoutes.logIn);
    }
    clearTransactions();
  };

  const clearTransactions = () => {
    hController.clearAllTransactions();
  };

  const deductBalance = (amount) => {
    setState({
      state: "user",
      value: { ...state.user, balance: state.user.balance - amount },
    });
  };

  return {
    formStateProps,
    user: state.user,
    values,
    isFormValid,
    handleFormSubmit,
    deductBalance,
    isLoading,
    isLoggedIn: state !== null ? (state.token !== null ? true : false) : false,
    logOut,

    toaster: <ToastContainer />,
  };
};

export default useLoginController;
