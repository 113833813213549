import React, { useState } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

/**
 * 
 * @param {object} props 
 * @param {number=} props.items
 * @param {(value)=>void} props.onChanged
 * @param {string=} props.className
 * @param {string=} props.placeHolder
 * @param {Boolean=} props.obsure

 */
const OtpField = ({
  length = 4,
  onChanged,
  placeHolder = "",
  obsure,
  className,
}) => {
  const [value, setvalue] = useState(Array(length).fill(placeHolder));

  const handleChange = (e, pos) => {
    let val = [];
    val = [...value];
    val.splice(pos, 1, e.target.value);
    const otpValue = val.join("").replaceAll(placeHolder, "");
    onChanged(otpValue);
    setvalue(val);
    if (e.target.nextElementSibling && e.target.value !== "") {
      e.target.nextElementSibling.focus();
    }
  };

  const handleKeyDown = (e) => {
    if (
      e.key === "Backspace" &&
      e.target.previousElementSibling &&
      e.target.value === ""
    ) {
      e.target.previousElementSibling.focus();
      return;
    }
  };
  // console.log(value);
  return (
    <div className="flex-center space-x-3 w-full justify-between">
      {value.map((digit, pos) => {
        // console.log(digit, "Digit");
        const theDigit = obsure ? (digit !== "" ? "*" : "") : digit;
        return (
          <input
            key={pos}
            autoComplete="one-time-code"
            inputMode="numeric"
            pattern="\d{1}"
            maxLength={1}
            value={theDigit}
            className={twMerge(
              `w-[50px] h-[50px] tb:w-[80px] tb:h-[80px] md:w-[90px] md:h-[90px] border-sgrey border rounded-2xl text-center text-base md:text-2xl  font-semibold outline-none focus:border-sprimary  transition-all flex-center ${className}`
            )}
            type="text"
            onChange={(e) => handleChange(e, pos)}
            onFocus={(e) => {
              e.target.setSelectionRange(0, e.target.value.length);
            }}
            onKeyDown={(e) => handleKeyDown(e)}
          />
        );
      })}
    </div>
  );
};

OtpField.propTypes = {
  length: PropTypes.number,
  onChanged: PropTypes.func,
  placeHolder: PropTypes.string,
  verifyOtp: PropTypes.func,
  className: PropTypes.string,
};

export default OtpField;
