import useMediaQuery from "hooks/useMediaQuery";
import bg_decor from "assets/bg_decor.png";

const AuthContainer = ({ children, modalHeight }) => {
  const { height } = useMediaQuery();

  return (
    <div
      className="bg-gradient fixed w-full overflow-clip h-[100dvh]"
      // // style={{ height: `${height}px` }}
      // style={{ height: `${height}px` }}
    >
      <img src={bg_decor} alt="" className="absolute top-6 left-0 w-full" />
      <div
        className={`${
          modalHeight ?? "h-[50%]"
        } w-[100vw] bg-white absolute bottom-0 left-0  rounded-t-[30px]`}
      ></div>
      {children}
    </div>
  );
};

export default AuthContainer;
