import React from "react";
import PropTypes from "prop-types";
import { IconArrowForwardIos } from "utils/app_icons";



const PaymentItem = ({ icon, onPressed, title, subTitle, showIcon=false }) => {
  return (
    <div
      className="rounded-xl px-3 py-3 bg-sgrey-5 flex cursor-pointer items-center space-x-3 justify-between"
      onClick={onPressed}
    >
      <div className="flex space-x-3">
      <div className="h-11 w-11 bg-slilac rounded-lg flex justify-center items-center">
        {icon}
      </div>
      <div className="flex flex-col justify-center">
        <h3 className="text-sm font-myriadProSemibold text-sblack-600">
          {title}
        </h3>
        <p className="text-xs text-sgrey-2">{subTitle}</p>
      </div>
      </div>
      {showIcon&&<IconArrowForwardIos />}
    </div>
  );
};

PaymentItem.propTypes = {
  icon: PropTypes.element,
  onPressed: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};
export default PaymentItem;
