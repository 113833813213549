import React from 'react'
import backIcon from 'assets/icons/back.png'
import useAppNavigator from 'utils/app_navigation'

const CustomAppBar = ({title, className}) => {
  const appNavigator  = useAppNavigator()
  return (
    <div className={`h-16 z-[1000] fixed w-full flex items-center px-4 top-0 left-0 ${className}`}>
        <div className='hover:bg-sprimary-200 h-10 w-10 transition-all duration-500 rounded-full flex-center cursor-pointer' onClick={() => appNavigator.back()}><img src={backIcon} alt='back' className='w-[17px]'/></div>
    </div>
  )
}

export default CustomAppBar