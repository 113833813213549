class CustomResponse {
  /**
   * @param {object} props
   * @param {string=} props.message
   * @param {int=} props.statusCode
   * @param {boolean=} props.customOkCondition
   * @param {string=} props.submessage
   * @param {any=} props.data
   */

  constructor({
    data = {},
    message = "",
    statusCode = 404,
    customOkCondition,
    submessage = "",
  }) {
    this.data = data;
    this.message = message;
    this.statusCode = statusCode;
    this.submessage = submessage;
    console.log("Status", this.statusCode);
    console.log(
      customOkCondition ?? (statusCode === 200 || statusCode === 201)
    );
    this.isOk = customOkCondition ?? (statusCode === 200 || statusCode === 201);
  }
}

export default CustomResponse;
