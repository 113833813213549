import { useNavigate } from "react-router-dom";

const useAppNavigator = () => {
  const navigate = useNavigate();
  /**
   *
   * @param {string} pagePath
   * @param {Object} [props]
   * @param {any=} props.state
   */
  const to = (pagePath, props) => {
    navigate(pagePath, props);
  };

  /**
   *
   * @param {string} pagePath
   * @param {Object} [props]
   * @param {any=} props.state
   */
  const offAllAndto = (pagePath, props) => {
    navigate(pagePath, { replace: true, ...props });
  };

  const back = (numOfPages) => {
    navigate(numOfPages ? numOfPages * -1 : -1);
  };

  return { to, back, offAllAndto };
};

export default useAppNavigator;
