import CustomButton from "components/button";
import NavBar from "components/layout/nav_bar";
import PageLayout from "components/layout/page_layout";
import Scaffold from "components/layout/scaffold";
import React from "react";

const BankTransfer = () => {
  return (
    <Scaffold appBar={<NavBar title="Bank Transfer" canGoBack={true} />}>
      <PageLayout animationType="fadeIn">
        <div className="py-3 px-4 ">
          <div className="card-bg rounded-2xl space-y-4 text-white px-6 py-7">
            <p className="text-xs">Fund your Skitpay Wallet. Kindly make payment to the account below</p>
            <p className="font-myriadProSemibold">Bank: Access Bank</p>
            <p className="font-myriadProSemibold">Account type: Savings</p>
            <p className="text-2xl font-myriadProBold">0123456789</p>
          </div>

          <div className="py-20">
            <CustomButton title="i have sent the money" />
          </div>
        </div>
      </PageLayout>
    </Scaffold>
  );
};

export default BankTransfer;
