import NavBar from "components/layout/nav_bar";
import PageLayout from "components/layout/page_layout";
import Scaffold from "components/layout/scaffold";
import React from "react";
import AppRoutes from "router/route_paths";
import { IconBank, IconProfile, IconSend } from "utils/app_icons";
import useAppNavigator from "utils/app_navigation";
import PaymentItem from "../main_activity/payment/sections/payment_item";

const DepositMoney = () => {
  const navigate = useAppNavigator();
  return (
    <Scaffold appBar={<NavBar title="Deposit Money" canGoBack={true} />}>
      <PageLayout animationType="fadeIn">
        <div className="py-3 space-y-4 px-4">
          <PaymentItem
            onPressed={() => navigate.to(AppRoutes.creditDebit)}
            icon={<IconBank />}
            title="Credit or Debit card"
            subTitle="Deposit directly from your credit/debit card"
          />
          <PaymentItem
            onPressed={() => navigate.to(AppRoutes.bankTransfer)}
            icon={<IconSend className="text-white" />}
            title="Bank transfer"
            subTitle="Deposit into your wallet directly from your bank"
          />
        </div>
      </PageLayout>
    </Scaffold>
  );
};

export default DepositMoney;
